/*global FB*/
import React, { Component } from "react";
import { func, string } from "prop-types";
import Styles from "./LiveUserInfoModal.module.scss";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FacebookClientId } from "../../config";

export default class LiveUserInfoModal extends Component {
  static propTypes = {
    handleUsernameChange: func.isRequired,
    handleEmailChange: func.isRequired,
    emailAndUsernameSubmit: func.isRequired,
    handleIdChange: func.isRequired,
    liveUsername: string,
    liveEmail: string
  };

  state = {
    showModal: true,
    guestSignIn: false,
    facebookUsername: "",
    facebookEmail: "",
    profilePicture: "",
    facebookId: ""
  };

  async componentDidMount() {
      const appId = await FacebookClientId();
    this.setState({ showModal: !this.hasUsernameAndEmail() });

    window.fbAsyncInit = function() {
      FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: "v2.1"
      });
      FB.getLoginStatus(
        function(response) {
          if (response.status === "connected") {
            this.fetchProfile();
          }
        }.bind(this)
      );
    }.bind(this);

    (function(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        `//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId=${appId}`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  completeFacebookSignIn = (name, email) => {
    const { emailAndUsernameSubmit } = this.props;

    emailAndUsernameSubmit();
    this.setCookie("liveSalesEmail", email);
    this.setCookie("liveSalesUsername", name);
    this.setState({ showModal: false });
  };

  //Load profile for user already signed into Facebook
  fetchProfile = () => {
    const { handleEmailChange, handleUsernameChange, handleIdChange } = this.props;

    FB.api(
      "/me",
      { fields: "name, email, picture, id" },
      function(response) {
        handleUsernameChange(response.name);
        handleEmailChange(response.email);
        handleIdChange(response.id);

        this.setState({
          profilePicture: response.picture.data.url,
          facebookUsername: response.name,
          facebookEmail: response.email,
          facebookId: response.id
        });
      }.bind(this)
    )
  };

  //Load profile for user sign in
  loadProfile = () => {
    const { handleEmailChange, handleUsernameChange, handleIdChange } = this.props;

    const { email, name } = this.state;

    FB.api(
      "/me",
      { fields: "name, email" },
      function(response) {
        handleUsernameChange(response.name);
        handleEmailChange(response.email);
        handleIdChange(response.id);

        this.setState({
          facebookUsername: response.name,
          facebookEmail: response.email,
          facebookId: response.id
        });

        this.completeFacebookSignIn(name, email);
      }.bind(this)
    )
  };

  checkResponse = response => {
    if (response.status === "connected") {
      this.loadProfile();
    }
  };

  facebookSignIn = () => {
    FB.login((this.checkResponse), { scope: "email"});
  };

  hasUsernameAndEmail() {
    const {
      handleEmailChange,
      handleUsernameChange,
      emailAndUsernameSubmit
    } = this.props;

    const email = this.getCookieValue("liveSalesEmail") || "";
    const username = this.getCookieValue("liveSalesUsername") || "";

    if (email !== "") {
      handleEmailChange(email);
    }

    if (username !== "") {
      handleUsernameChange(username);
    }

    if (email !== "" && username !== "") {
      emailAndUsernameSubmit();
    }

    return email !== "" && username !== "";
  }

  handleSubmit = event => {
    const {
      liveUsername,
      liveEmail,
      handleEmailChange,
      handleUsernameChange,
      emailAndUsernameSubmit
    } = this.props;

    handleEmailChange(liveEmail);
    handleUsernameChange(liveUsername);
    emailAndUsernameSubmit();
    this.setCookie("liveSalesEmail", liveEmail);
    this.setCookie("liveSalesUsername", liveUsername);
    this.setState({ showModal: false });
    event.preventDefault();
  };

  setCookie(cookieName, cookieValue) {
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    document.cookie =
      cookieName +
      "=" +
      cookieValue +
      "; expires=" +
      expirationDate.toUTCString();
  }

  getCookieValue(name) {
    const cookie = {};
    document.cookie.split(";").forEach(function(cookieString) {
      const [key, value] = cookieString.split("=");
      cookie[key.trim()] = value;
    });
    return cookie[name];
  }

  guestSignIn = () => {
    this.setState({ guestSignIn: true });
  };

  render() {
    const { handleUsernameChange, handleEmailChange } = this.props;

    const {
      showModal,
      facebookUsername,
      facebookEmail,
      facebookId,
      profilePicture,
      guestSignIn
    } = this.state;

    return (
      <>
        {showModal && (
          <div className="row">
            <div className="offset-lg-2 col-md-4 col-lg-3 my-2 my-md-0">
              <div className={Styles.col_left}>
                <h4>Join Live Sale</h4>
                <div className={Styles.col_message}>
                  In order to join this amazing Live Sale we need your name and
                  email address to identify your chat comments to the retailer.
                  Please enter your name and email address.
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-5">
              <div className={Styles.col_form}>
                {facebookUsername && facebookEmail ? (
                  <button
                    className={Styles.continueAsButton}
                    icon="fa fa-facebook"
                    onClick={() =>
                      this.completeFacebookSignIn(
                        facebookUsername,
                        facebookEmail,
                        facebookId
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faFacebook} /> Continue As{" "}
                    {facebookUsername}
                    <img
                      src={profilePicture}
                      className={Styles.profilePicture}
                    />
                  </button>
                ) : (
                  <button
                    className={Styles.facebookButton}
                    icon="fa fa-facebook"
                    onClick={this.facebookSignIn}
                  >
                    <FontAwesomeIcon icon={faFacebook} /> Sign In with Facebook
                  </button>
                )}
                {!guestSignIn ? (
                  <button
                    className={`btn btn-block ${Styles.guestButton}`}
                    onClick={this.guestSignIn}
                  >
                    Continue as Guest
                  </button>
                ) : null}
                {guestSignIn ? (
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className={Styles.input_header}>
                        Full Display Name
                      </label>
                      <input
                        type="text"
                        className={`${Styles.text_input} form-control`}
                        onChange={handleUsernameChange}
                        required={true}
                        maxLength={50}
                        value={this.props.facebookUsername}
                      />
                    </div>
                    <div className="form-group">
                      <label className={Styles.input_header}>
                        Email Address
                      </label>
                      <input
                        type="email"
                        className={`${Styles.text_input} form-control`}
                        onChange={handleEmailChange}
                        required={true}
                        pattern="^[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                        maxLength={254}
                        value={this.props.facebookEmail}
                      />
                    </div>
                    <button
                      type="submit"
                      className={`btn btn-block ${Styles.button}`}
                      disabled={false}
                    >
                      Join Live Sale
                    </button>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
