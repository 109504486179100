import React, { Component, MouseEventHandler } from "react";
import LoadingButton from "../../Buttons";
import { CheckoutDeliveryDataState } from "../../../store/state/checkout/delivery";
import {
  withFormik,
  InjectedFormikProps,
  Form,
  Field,
  ErrorMessage
} from "formik";
import classnames from "classnames";
import Styles from "./BillingForm.module.scss";
import * as Yup from "yup";
import StateOptions from "../StateOptions";
import { CheckoutBillingDataState } from "../../../store/state/checkout/billing";
import PayForm from "./PayForm";

interface Props {
  storeId: number;
  hasToken: boolean;
  nuveiRequestId?: string;
  editingPayForm: boolean;
  editingAddress: boolean;
  payFormUrl: string;
  delivery?: CheckoutDeliveryDataState;
  existing?: CheckoutBillingDataState;
  submitAction: (data: CheckoutBillingDataState) => void;
  paymentInProcess: boolean;
  failureErrors: {
    [p in keyof CheckoutBillingDataState | "other"]?: string;
  }
}

interface ComponentState {
  needsResubmission: boolean;
}

class InnerForm extends Component<
  InjectedFormikProps<Props, CheckoutBillingDataState>,
  ComponentState
> {
  state = {
    needsResubmission: false
  };

  updatePaymentToken = (token: string) => {
    this.props.setFieldValue("paymentToken", token);
    this.props.setFieldTouched("paymentToken");

    if (this.props.isValid) {
      this.props.submitForm();
    } else {
      this.props.submitForm();
      this.setState({
        needsResubmission: true
      });
    }
  };

  failedPaymentToken = (code: string, message: string) => {
    console.error("Payment token error", code, message);
    this.props.setFieldError("paymentToken", `Error Collecting Payment Info: ${code}. Please contact your retailer for assistance.`);
    this.props.setFieldTouched("paymentToken");
  };

  setSameAddressAsShipping: MouseEventHandler<HTMLInputElement> = (event) => {
    const { delivery } = this.props;
    if (event.currentTarget.checked) {
      this.props.setFieldValue("line1", (delivery && delivery.line1) || "");
      this.props.setFieldValue("line2", (delivery && delivery.line2) || "");
      this.props.setFieldValue("city", (delivery && delivery.city) || "");
      this.props.setFieldValue("state", (delivery && delivery.state) || "");
      this.props.setFieldValue("zipCode", (delivery && delivery.zipCode) || "");
      this.props.setFieldTouched("line1", false);
      this.props.setFieldTouched("line2", false);
      this.props.setFieldTouched("city", false);
      this.props.setFieldTouched("state", false);
      this.props.setFieldTouched("zipCode", false);
      this.props.setFieldValue("sameAddressAsShipping", true);
    } else {
      this.props.setFieldValue("line1", "");
      this.props.setFieldValue("line2", "");
      this.props.setFieldValue("city", "");
      this.props.setFieldValue("state", "");
      this.props.setFieldValue("zipCode", "");
      this.props.setFieldTouched("line1", false);
      this.props.setFieldTouched("line2", false);
      this.props.setFieldTouched("city", false);
      this.props.setFieldTouched("state", false);
      this.props.setFieldTouched("zipCode", false);
      this.props.setFieldValue("sameAddressAsShipping", false);
    }
  }

  addressEditForm = () => {
    const {
      failureErrors,
      errors,
      touched,
      delivery
    } = this.props;
    return <>
      <Field type="hidden" name="sameAddressAsShipping" />
      {delivery && delivery.method === "SHIPPING" && <label className={Styles.sameAsShipping}>
        <input type="checkbox" defaultChecked={this.props.values.sameAddressAsShipping} onClick={this.setSameAddressAsShipping} />
        <span className={Styles.checkmark} /> Same as shipping address
      </label>}
      <label className={Styles.input_header}>Billing Address</label>
      <Field
        type="text"
        name="line1"
        autoComplete="billing address-line1"
        className={classnames({
          [Styles.input_box]: true,
          "form-control": true,
          [Styles.invalid]: (failureErrors.line1 && !touched.line1) || (errors.line1 && touched.line1)
        })}
        disabled={this.props.values.sameAddressAsShipping}
      />
      <div className={Styles.inputfailure}>
        <ErrorMessage name="line1" /> {!touched.line1 && failureErrors.line1}
      </div>
      <label className={Styles.input_header}>Billing Address Line 2</label>
      <Field
        type="text"
        name="line2"
        autoComplete="billing address-line2"
        className={classnames({
          [Styles.input_box]: true,
          "form-control": true,
          [Styles.invalid]: (failureErrors.line2 && !touched.line2) || (errors.line2 && touched.line2)
        })}
        disabled={this.props.values.sameAddressAsShipping}
      />
      <div className={Styles.inputfailure}>
        <ErrorMessage name="line2" /> {!touched.line2 && failureErrors.line2}
      </div>
      <label className={Styles.input_header}>City</label>
      <Field
        type="text"
        name="city"
        autoComplete="billing address-level2"
        className={classnames({
          [Styles.input_box]: true,
          "form-control": true,
          [Styles.invalid]: (failureErrors.city && !touched.city) || (errors.city && touched.city)
        })}
        disabled={this.props.values.sameAddressAsShipping}
      />
      <div className={Styles.inputfailure}>
        <ErrorMessage name="city" /> {!touched.city && failureErrors.city}
      </div>
      <div className="row">
        <div className="col-6">
          <label className={Styles.input_header}>State</label>
          <Field
            component="select"
            name="state"
            autoComplete="billing address-level1"
            className={classnames({
              [Styles.input_box]: true,
              "form-control": true,
              [Styles.invalid]: (failureErrors.state && !touched.state) || (errors.state && touched.state)
            })}
            disabled={this.props.values.sameAddressAsShipping}
          >
            <StateOptions />
          </Field>
          <div className={Styles.inputfailure}>
            <ErrorMessage name="state" /> {!touched.state && failureErrors.state}
          </div>
        </div>
        <div className="col-6">
          <label className={Styles.input_header}>Zip Code</label>
          <Field
            type="text"
            name="zipCode"
            pattern="[0-9]{5}"
            autoComplete="billing postal-code"
            className={classnames({
              [Styles.input_box]: true,
              "form-control": true,
              [Styles.invalid]: (failureErrors.zipCode && !touched.zipCode) || (errors.zipCode && touched.zipCode)
            })}
            disabled={this.props.values.sameAddressAsShipping}
          />
          <div className={Styles.inputfailure}>
            <ErrorMessage name="zipCode" /> {!touched.zipCode && failureErrors.zipCode}
          </div>
        </div>
      </div>
    </>;
  };

  render() {
    const {
      failureErrors,
      errors,
      touched,
      editingPayForm,
      editingAddress
    } = this.props;
    const {
      needsResubmission
    } = this.state;
    return (
      <div className={Styles.padTopBody}>
        <Form>
          {
            editingAddress
            ? this.addressEditForm()
            : <>
              <Field type="hidden" name="sameAddressAsShipping" />
              <Field type="hidden" name="line1" />
              <Field type="hidden" name="line2" />
              <Field type="hidden" name="city" />
              <Field type="hidden" name="state" />
              <Field type="hidden" name="zipCode" />
            </>
          }
          <Field type="hidden" name="paymentToken" />
          <div className={Styles.inputfailure}>
            {touched.paymentToken && errors.paymentToken} {!touched.paymentToken && failureErrors.paymentToken} {failureErrors.other}
          </div>
          {
            editingPayForm && !needsResubmission
            ? <PayForm
              onPaymentToken={this.updatePaymentToken}
              onPaymentTokenFailure={this.failedPaymentToken}
              payFormUrl={this.props.payFormUrl}
            />
            : <LoadingButton
                onClick={event => {this.props.submitForm(); event.preventDefault();}}
                disabled={!this.props.isValid}
                block
              >
                Save
              </LoadingButton>
          }
        </Form>
      </div>
    );
  }
}

const validationSchema = Yup.object().shape<CheckoutBillingDataState>({
  sameAddressAsShipping: Yup.boolean(),
  line1: Yup.string()
    .label("Line 1")
    .required()
    .max(255),
  line2: Yup.string()
    .label("Line 2")
    .max(255),
  city: Yup.string()
    .label("City")
    .required()
    .max(50),
  state: Yup.string()
    .label("State")
    .required()
    .matches(/^[a-zA-Z]{2}$/),
  zipCode: Yup.string()
    .label("ZIP Code")
    .required()
    .matches(/^\d{5}$/, "ZIP Code must be 5 digits"),
  paymentToken: Yup.string()
    .label("Payment Information")
    .required("Please verify your payment information")
});

const propsValueMap: (props: Props) => CheckoutBillingDataState = ({ existing, delivery }) =>
  existing || {
    sameAddressAsShipping: (delivery && delivery.method === "SHIPPING") || false,
    line1: (delivery && delivery.line1) || "",
    line2: (delivery && delivery.line2) || "",
    city: (delivery && delivery.city) || "",
    state: (delivery && delivery.state) || "",
    zipCode: (delivery && delivery.zipCode) || "",
    paymentToken: ""
  };

export const BillingForm = withFormik<Props, CheckoutBillingDataState>({
  isInitialValid: (props) => {
    let initialValues = propsValueMap(props);
    return validationSchema.isValidSync(initialValues);
  },
  mapPropsToValues: propsValueMap,
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    props.submitAction(values);
  },
  enableReinitialize: true
})(InnerForm);

export default BillingForm;
