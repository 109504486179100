import React from "react";
import "./scss/index.scss";
import App from "./App";
import { NotFound } from "./NotFound";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "components/ErrorBoundary";
import withTracker from "./withTracker";
import {
  SetUpAnalyticsProperty,
  SetUpPixelProperty
} from "analytics";
import {
  GoogleAnalyticsId,
  GoogleAnalyticsSamplePercentage,
  LularoeGoogleAnalyticsId,
  LularoeGoogleAnalyticsSamplePercentage,
  FacebookPixelAnalyticsId,
  LularoeAutoLinkDomains,
} from "./config";

// Redux Store
import { Provider } from "react-redux";
import ScrollToTop from "components/ScrollToTop";
import { GoogleAnalyticsTrackerKey, SetUpAutoLinking } from "./analytics";

const analyticsPropertyIds = [
  GoogleAnalyticsId(),
  GoogleAnalyticsSamplePercentage(),
  LularoeGoogleAnalyticsId(),
  LularoeGoogleAnalyticsSamplePercentage(),
  FacebookPixelAnalyticsId(),
];

const BuildRoot = async (cartToken) => {
  try {
    const [
      rsGoogle,
      rsPercent,
      llrGoogle,
      llrPercent,
      rsPixel,
    ] = await Promise.all(analyticsPropertyIds);
    const autolinkDomains = await LularoeAutoLinkDomains();
    rsGoogle &&
      SetUpAnalyticsProperty(GoogleAnalyticsTrackerKey.Rs, rsGoogle, {
        sampleRate: rsPercent || "100",
      });

    llrGoogle &&
      SetUpAnalyticsProperty(GoogleAnalyticsTrackerKey.LuLaRoe, llrGoogle, {
        allowLinker: true,
        sampleRate: llrPercent || "100",
      }, autolinkDomains);
    rsPixel && SetUpPixelProperty(rsPixel);
  } catch {
    console.log("Failed to await analytics initialization; Continuing anyway.");
  }

  const Root = ({ store, history }) => (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <Route
          exact
          path={"/"}
          render={() => {
            window.location = "https://www.lularoe.com/shop-lularoe/";
            return null;
          }}
        />
        <ErrorBoundary>
          <Switch>
            {/* Provide a component for not found errors; otherwise try falling through to our app component */}
            {/* I might move this to the lower-level switch. Not sure! */}
            <Route exact path={"/404"} component={NotFound} />
            <Route exact path={"/500"} component={NotFound} />
            <Route
              path={"/:storeName/previoussession/:sessionId"}
              component={withTracker(({ match }) => (
                <App
                  storeAlias={match.params.storeName}
                  previousSessionId={match.params.sessionId}
                />
              ))}
            />
            <Route
              path={"/:storeName"}
              component={withTracker(({ match }) => (
                <App
                  storeAlias={match.params.storeName}
                  cartToken={cartToken}
                />
              ))}
            />
          </Switch>
        </ErrorBoundary>
      </ConnectedRouter>
      {/* </PersistGate> */}
    </Provider>
  );

  Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return Root;
};

export default BuildRoot;
