import { DeliveryMethod } from "../../../enums/index";
import { RequestStateBase } from "../requestStateBase";

export interface CheckoutEstimateState
  extends RequestStateBase<
    CheckoutShippingEstimateRequestState | CheckoutPickupEstimateRequestState,
    CheckoutEstimateResponseState | FailedEstimateResponseState
  > {}

interface CheckoutEstimateRequestStateBase {
  listingIds: number[];
  promoCode: string;
  utcOffset: number;
  deliveryMethod: DeliveryMethod;
  billing: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
  };
  customer: {
    email: string;
  };
}
export interface CheckoutShippingEstimateRequestState
  extends CheckoutEstimateRequestStateBase {
  deliveryMethod: DeliveryMethod.Shipping;
  delivery: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export interface CheckoutPickupEstimateRequestState
  extends CheckoutEstimateRequestStateBase {
  deliveryMethod: DeliveryMethod.Pickup;
}

export interface CheckoutEstimateResponseState {
  isSuccess: true;
  subtotal: number;
  promoDiscount: number;
  promoCodeError: string;
  itemTax: number;
  dollarDiscountAmount: number;
  offerDiscountAmount: number;
  shippingAmount: number;
  shippingTax: number;
  grandTotal: number;
}

export interface DiscountEstimateRequest {
  listingIds: number[];
  promoCode: string;
}

export interface DiscountEstimateResponseState {
  discountDollarAmount: number;
}

export interface FailedEstimateResponseState {
  isSuccess: false;
  promoCodeError?: string;
  failureReason: string;
}

export function initialEstimateState(): CheckoutEstimateState {
  return {
    inProcess: false,
    request: undefined,
    response: undefined
  };
}
