import React, { Component, ChangeEventHandler } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Button } from 'react-bootstrap';
import queryString from "query-string";
import { push, replace } from "connected-react-router";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import LoadingSpinner from "../LoadingSpinner";
import Styles from "./ProductList.module.scss";
import { ListingPatternTagDetails } from '../../apis/listingsEndpoints';
import PatternListItem from "./PatternListItem/PatternListItem";
import { PatternItem } from "../../utilities/patterns";
import { State } from "store/state";
import { TagCategoryDefs } from '../../enums/TagCategoryDefs';
import { ClaimStatus } from "../../enums";

interface StateProps {
  alias: string;
}
interface OwnProps {
  showFilters: boolean;
  filterParams?: Filters;
  showTypeFilter: boolean;
  typeListingGroup?: boolean;
  loading: boolean;
  typeTags: ListingPatternTagDetails[];
  styleTags: ListingPatternTagDetails[];
  sizeTags: ListingPatternTagDetails[];
  collectionTags: ListingPatternTagDetails[];
  patterns?: PatternItem[];
  itemClassName?: string;
  listName: string;
  albumId?: number;
  sizeChartOpen: boolean;
  openSizeChart: (typeId: number) => void;
}

interface ComponentState {
  filters: Filters;
}

interface Filters {
  type?: number;
  style?: number;
  size?: number;
  collection?: number;
}

type Props = OwnProps & StateProps & ThunkDispatchProp;

class PatternList extends Component<Props, ComponentState> {
  static defaultProps = {
    showFilters: false,
    loading: false,
    showTypeFilter: true
  };

  state: ComponentState = {
    filters: this.props.filterParams || {}
  };

  componentDidUpdate() {
    const { filters } = this.state;
    const { filterParams } = this.props;

    if (filters.type !== (filterParams?.type || 0)) {
      this.setState((prevState) => ({
        ...prevState,
        filters: {
          type: filterParams?.type || 0,
          size: filterParams?.size || 0,
          style: filterParams?.style || 0
        }
      }))
    }
  }

  handleChangeFilter: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { alias, typeListingGroup } = this.props;
    const { name, value } = event.target;
    const newId = value ? parseInt(value) : undefined;
    let path = value ? `/${alias}/type/${value}` : `/${alias}/shop`;

    if (name == "type" && typeListingGroup) {
      this.props.dispatch(
        push({
          pathname: path
        })
      );
      this.setState({
        filters: {
          type: newId
        }
      });
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: newId
          }
        }),
        () => {
          this.props.dispatch(
            replace({
              search: `?${queryString.stringify(this.state.filters)}`
            })
          );
        }
      );
    }
  };

  filterPatterns(
    patterns: PatternItem[],
    filters: Filters
  ): PatternItem[] {
    const filteredPatterns = patterns.filter(pattern => {
      let validPattern = true;
      let filteredListings = pattern.listingDetails;

      if (filters.type && validPattern) {
        validPattern = !!pattern.tagDetails
          .find((tag) => tag.tagCategoryId === TagCategoryDefs.Type && tag.id === filters.type);
      }

      if (filters.style && validPattern) {
        validPattern = !!pattern.tagDetails
          .find((tag) => tag.tagCategoryId === TagCategoryDefs.Style && tag.id === filters.style);
      }

      if (filters.size && validPattern) {
        filteredListings = filteredListings.filter((listing) => (
          (listing.quantity ||
            listing.claimBySessions.find((claim) => claim.claimStatus === ClaimStatus.ClaimedByYou)
          ) &&
          listing.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size && tag.id === filters.size)
        ));
      }

      return validPattern && filteredListings.length;
    });

    return filteredPatterns;
  }

  availableFilterTags(
    filterType: keyof Filters,
    tagCategoryId: number,
    tags: ListingPatternTagDetails[],
    patterns: PatternItem[],
    filters: Filters
  ) {
    // Filter the items only by the OTHER types of filters.
    const filteredPatterns = this.filterPatterns(patterns, {
      ...filters,
      [filterType]: undefined
    });

    return tags.filter((tag) => (
      filteredPatterns.some((pattern) => (
        pattern.tagDetails.some((patternTag) => (
          patternTag.tagCategoryId === tagCategoryId && patternTag.id === tag.id
        )) ||
        pattern.listingDetails.some(listing => (
          (listing.quantity ||
            listing.claimBySessions.find((claim) => claim.claimStatus === ClaimStatus.ClaimedByYou)
          ) &&
          listing.tagDetails.find(listingTag => listingTag.tagCategoryId === tagCategoryId && listingTag.id === tag.id)
        ))
      ))
    ));
  }

  clearFilters() {
    if (Object.values(this.state.filters).some((value) => !!value)) {
      this.setState((prevState) => ({ ...prevState, filters: {} }));
    }
  }

  selectForTags(
    tagType: keyof Filters,
    tags: ListingPatternTagDetails[],
    placeholder: string,
    current?: number
  ) {
    return (
      <select
        value={current}
        className="custom-select"
        name={tagType}
        onChange={this.handleChangeFilter}
      >
        <option value="">{placeholder}</option>
        {tags.map((tag) => (
          <option key={tag.id} value={tag.id}>
            {tag.name}
          </option>
        ))}
      </select>
    );
  }

  render() {
    const {
      loading,
      patterns,
      showFilters,
      showTypeFilter,
      itemClassName,
      listName,
      albumId,
      typeTags,
      sizeTags,
      styleTags
    } = this.props;
    const { filters } = this.state;
    const filteredPatterns = patterns
      ? this.filterPatterns(patterns, filters)
      : null;

    const types = patterns
      ? this.availableFilterTags("type", TagCategoryDefs.Type, typeTags, patterns, filters)
      : null;
    const styles = patterns
      ? this.availableFilterTags("style", TagCategoryDefs.Style, styleTags, patterns, filters)
      : null;
    const sizes = patterns
      ? this.availableFilterTags("size", TagCategoryDefs.Size, sizeTags, patterns, filters)
      : null;

    if (loading) {
      return (
        <div className="row">
          <div className="col-12 mb-5">
            <LoadingSpinner />
          </div>
        </div>
      );
    }

    if (!filteredPatterns || filteredPatterns.length === 0) {
      return (
        <div className="row">
          <div className="col-12 mb-5">
            <p>There are no products matching the search!</p>
          </div>
        </div>
      );
    }

    const maxPatterns = 1002; // first composite of 2 and 3 (the components of our responsive row widths) after 1000
    const filteredLength = filteredPatterns.length;
    const fullyFiltered = filters.size && filters.style && filters.type;
    const tooManyPatterns = filteredLength > maxPatterns && !fullyFiltered;
    const limitedPatterns = tooManyPatterns ? filteredPatterns.splice(0, maxPatterns) : filteredPatterns;

    return (
      <>
        {showFilters && (
          <div className="row mb-3">
            {types && showTypeFilter && (
              <div className="col-12 col-sm-4 form-group">
                <label>Type</label>
                {this.selectForTags("type", types, "All Types", filters.type)}
              </div>
            )}
            {sizes && (
              <div
                className={`col-12 col-sm-${showTypeFilter ? 4 : 6} form-group`}
              >
                <label>Size</label>
                {this.selectForTags("size", sizes, "All Sizes", filters.size)}
              </div>
            )}
            {styles && (
              <div
                className={`col-12 col-sm-${showTypeFilter ? 4 : 6} form-group`}
              >
                <label>Style</label>
                {this.selectForTags(
                  "style",
                  styles,
                  "All Styles",
                  filters.style
                )}
              </div>
            )}
          </div>
        )}
        <div className="row">
          {!filteredPatterns || filteredPatterns.length === 0 ? (
            <div className="col-12 mb-5">
              <p>There are no products matching the search!</p>
              {this.clearFilters()}
            </div>
          ) : (
            <>
              {limitedPatterns.map((item) => (
                <div
                  key={item.listingPatternId}
                  className={`col-6 col-md-4 col-xl-2 ${Styles.listItem} ${itemClassName}`}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <PatternListItem
                    key={item.listingPatternId}
                    item={item}
                    listName={listName}
                    albumId={albumId}
                    sizeChartOpen={this.props.sizeChartOpen}
                    openSizeChart={this.props.openSizeChart}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        {tooManyPatterns && (
          <>
            <div className="row">
              <div className="col-12 text-center">
                <strong>There are too many results to show.</strong>&nbsp;
                Use the filter and sort options at the top of this page to see more styles that are relevant to you.
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <Button variant="link" onClick={() => window.scrollTo(0, 0)}>Back to Top</Button>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state) => {
  const {
    store: { alias }
  } = state;

  return {
    alias: alias
  };
};

export default connect(mapStateToProps)(PatternList);
