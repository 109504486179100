import { createSelector } from "reselect";
import { DateTime } from "luxon";
import { State } from "../store/state";
import { LiveEventState } from "../store/state/liveEvents";
import { activeAlbumsSelector } from "./albums";
import { PageUrls } from "../config";

export interface LiveEvent extends LiveEventState {
  eventUrl: string;
};

export const selectLiveEvents = createSelector<State, LiveEventState[], string, LiveEvent[]>([
  state => state.liveEvents,
  state => state.store.alias
], (rawEvents, alias) => rawEvents.map(event => ({
  ...event,
  eventUrl: PageUrls.LiveEventPage(alias, event.id)
})));

const blessLiveEvents = createSelector(
  [selectLiveEvents],
  (events) => {
    return events.filter(event => event.eventType === "Bless")
  }
);

export const activeLiveEvent = createSelector(
  [blessLiveEvents],
  (events) => {
    return events.find(
      event => !event.isEnded && event.isActive
    );
  }
);

export const upcomingLiveEvents = createSelector(
    [blessLiveEvents], events => {
        const currentDateEpoch = new Date().getTime();
        return events
            .filter(event => {
                if (event.isActive) return false;
                if (event.isEnded) return false;
                const eventStartDateEpoch = Date.parse(event.startDateTime);
                if (eventStartDateEpoch < currentDateEpoch) return false;
                return true;
            })
            .sort((eventA, eventB) => {
                return (
                    Date.parse(eventA.startDateTime) - Date.parse(eventB.startDateTime)
                );
            }
        );
    }
);

export const allActiveLiveEvent = createSelector(
  [selectLiveEvents],
  (events) => {
    return events.find(
      event => !event.isEnded && event.isActive
    );
  }
);

export const allUpcomingLiveEvents = createSelector(
    [selectLiveEvents], events => {
        const currentDateEpoch = new Date().getTime();
        return events
            .filter(event => {
                if (event.isActive) return false;
                if (event.isEnded) return false;
                const eventStartDateEpoch = Date.parse(event.startDateTime);
                if (eventStartDateEpoch < currentDateEpoch) return false;
                return true;
            })
            .sort((eventA, eventB) => {
                return (
                    Date.parse(eventA.startDateTime) - Date.parse(eventB.startDateTime)
                );
            }
        );
    }
);


export const visibleLiveEvents = createSelector(
  [activeLiveEvent, upcomingLiveEvents],
  (active, upcoming) => {
    return [active, ...upcoming].filter(event => !!event); // Filter out nulls
  }
);

export const formatDate = (date?: string, form: 'long' | 'short' = 'short') => {
  if (!date) return undefined;

  const dateTime = DateTime.fromISO(date);

  if (dateTime.hasSame(DateTime.local(), "day")) {
    return "Today";
  }

  switch (form) {
    case 'long':
      return dateTime.toLocaleString({
        weekday: "long",
        month: "long",
        day: "numeric"
      });
    case 'short':
      return dateTime.toLocaleString({
        weekday: "long",
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
    default:
      return dateTime.toLocaleString({
        weekday: "long",
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
  }

  return dateTime.toLocaleString({
    weekday: "long",
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  })
}

export const formatTime = (date?: string) => {
  if (!date) return undefined;

  const dateTime = DateTime.fromISO(date);

  return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
}
