import React, { Component } from "react";
import { FilteredProductList } from "../../components/ProductList";
import Styles from "./AlbumDetailPage.module.scss";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Redirect } from "react-router";
import { activeAlbumsSelector } from "../../utilities/albums";
import { State } from "../../store/state";
import { ProductManagerContext } from "../../components/ProductManager/ProductManagerContext";
import { AlbumState } from "../../store/state/albums";
import { TrackItemListView } from "../../analytics";
import {
  Timer,
  TimerActive,
  TimerInactive,
  DaysActive,
  Days,
  HoursActive,
  Hours,
  Minutes,
  Seconds
} from "../../components/Timer";
import "./styles.css";
import Lightbox from "react-images";
import "./lightbox.css";
import { getProductSizeChart } from "apis/productEndpoints";
import { NotFound } from "../../NotFound";

interface StateProps {
  album?: AlbumState;
  isLoading: boolean;
}

interface IState {
  sizeChartUrl: string;
  lightboxIsOpen: boolean;
}

interface OwnProps {
  albumId?: number;
  typeId?: number;
  query?: string;
  showTypeFilter?: boolean;
  typeListingGroup?: boolean;
}

type Props = StateProps & OwnProps & DispatchProp;

class AlbumDetailPage extends Component<Props, IState> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  constructor(props: Props) {
    super(props);
    this.openLightbox = this.openLightbox.bind(this);

    this.state = {
      sizeChartUrl: "",
      lightboxIsOpen: false
    };
  }

  async componentDidMount() {
    const { album, albumId } = this.props;
    if (albumId) {
      if (album && album.title) {
        TrackItemListView(album.title);
      }
    }
  }

  closeLightbox() {
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: false,
      sizeChartUrl: ""
    }));
  }

  openLightbox(typeId: number) {
    this.fetchSizeChartUrl(typeId);
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: true
    }));
  }

  fetchSizeChartUrl = async (typeId: number) => {
    const url = await getProductSizeChart(typeId);
    this.setState((state) => ({
      ...state,
      sizeChartUrl: url
    }));
  };

  facebookResponse = (response: any) => {
    console.log(response);
  };

  render() {
    const { sizeChartUrl, lightboxIsOpen } = this.state;
    const {
      isLoading,
      albumId,
      album,
      typeId,
      showTypeFilter,
      typeListingGroup
    } = this.props;

    if (album) {
      const { title, expirationDate } = album;

      return (
        <div>
          <Timer
            target={expirationDate}
            units={["days", "hours", "minutes", "seconds"]}
            maxDuration={2}
            maxDurationUnits="days"
          >
            <TimerActive>
              <div className={Styles.albumTimer}>
                <div className={Styles.timerTitle}>{title}</div>
                <div className={Styles.timerDetailText}>
                  Better hurry—this album expires in…
                </div>
                <div className={Styles.timerClock}>
                  <DaysActive>
                    <div className={Styles.counterColumn}>
                      <Days />
                    </div>
                  </DaysActive>
                  <HoursActive>
                    <div className={Styles.counterColumn}>
                      <Hours />
                    </div>
                  </HoursActive>
                  <div className={Styles.counterColumn}>
                    <Minutes />
                  </div>
                  <div className={Styles.counterColumn}>
                    <Seconds />
                  </div>
                  <div>
                    <DaysActive>
                      <div className={Styles.timerClockText}>Days</div>
                    </DaysActive>
                    <HoursActive>
                      <div className={Styles.timerClockText}>Hrs</div>
                    </HoursActive>
                    <div className={Styles.timerClockText}>Mins</div>
                    <div className={Styles.timerClockText}>Secs</div>
                  </div>
                </div>
              </div>
            </TimerActive>
            <TimerInactive>
              <div className={Styles.albumHeader}>
                <h2>{title}</h2>
              </div>
            </TimerInactive>
          </Timer>
          {sizeChartUrl && (
            <Lightbox
              images={[{ src: sizeChartUrl }]}
              isOpen={lightboxIsOpen}
              onClose={() => this.closeLightbox()}
              backdropClosesModal={true}
              showCloseButton={false}
              showImageCount={false}
              preventScroll={false}
            />
          )}
          <FilteredProductList
            albumId={albumId}
            showFilters={true}
            typeListingGroup={typeListingGroup}
            showTypeFilter={showTypeFilter}
            query={this.props.query}
            sizeChartOpen={lightboxIsOpen}
            openSizeChart={this.openLightbox}
          />
        </div>
      );
    }

    if (typeId) {
      return (
        <div>
          {sizeChartUrl && (
            <Lightbox
              images={[{ src: sizeChartUrl }]}
              isOpen={lightboxIsOpen}
              onClose={() => this.closeLightbox()}
              backdropClosesModal={true}
              showCloseButton={false}
              showImageCount={false}
              preventScroll={false}
            />
          )}
          <FilteredProductList
            showFilters={true}
            showTypeFilter={showTypeFilter}
            typeListingGroup={typeListingGroup}
            typeId={typeId.toString()}
            query={this.props.query}
            sizeChartOpen={lightboxIsOpen}
            openSizeChart={this.openLightbox}
          />
        </div>
      );
    }

    if (typeListingGroup) {
      return (
        <div>
          {sizeChartUrl && (
            <Lightbox
              images={[{ src: sizeChartUrl }]}
              isOpen={lightboxIsOpen}
              onClose={() => this.closeLightbox()}
              backdropClosesModal={true}
              showCloseButton={false}
              showImageCount={false}
              preventScroll={false}
            />
          )}
          <FilteredProductList
            showFilters={true}
            showTypeFilter={showTypeFilter}
            filterParams={{ type: 0, size: 0, style: 0 }}
            typeListingGroup={typeListingGroup}
            query={this.props.query}
            sizeChartOpen={lightboxIsOpen}
            openSizeChart={this.openLightbox}
          />
        </div>
      );
    }

    if (!isLoading && !album && !typeId && !typeListingGroup) {
      return <NotFound />;
    }
  }
  }


const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  ownProps
) => {
  const albums = activeAlbumsSelector(state);
  const album = albums.find((album) => album.id === ownProps.albumId);
  return {
    isLoading: state.app.loading,
    album,
  };
};

export default connect(mapStateToProps)(AlbumDetailPage);
