import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Link } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cartExpiration, cartCount } from "../../utilities/cart";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import Navigation from "../Navigation";
import { State } from "../../store/state";
import { Timer, Minutes, Seconds, TimerActive, Hours } from "../Timer";
import { activeAlbumsSelector } from "../../utilities/albums";
import Styles from "./Header.module.scss";
import { visibleLiveEvents } from "../../utilities/liveEvents";
import { Features } from "../../utilities/features";
import { GetActiveTypeTags } from "../../utilities/tags";
import { ListingPatternTagDetails } from "../../apis/listingsEndpoints";

interface StateProps {
  cartCount: number;
  storeDisplayName: string;
  storeLink: string;
  storeAboutLink: string;
  storeShopLink: string;
  storeStylesLink: string;
  storeAlbumsLink: string;
  storeLiveLink: string;
  storeSearchLink: string;
  storeCartLink: string;
  storeLooksLink: string;
  timer?: Date;
  storeId: number;
  hasAlbums: boolean;
  hasLive: boolean;
  location: string;
  typeTags: ListingPatternTagDetails[];
}

interface OwnProps {
  showCart: boolean;
  cartClick: () => void;
  clickOutside: () => void;
}

interface HeaderState {
  headerHeight: number;
  stickyNav: boolean;
}

type Props = StateProps & OwnProps;

class Header extends Component<Props, HeaderState> {
  state = {
    headerHeight: 0,
    stickyNav: false
  };

  updateStaleTimeout = 0;
  node: HTMLDivElement | null = null;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    clearTimeout(this.updateStaleTimeout);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const element = document.getElementById("js-sticky-header");

    if (element) {
      const headerHeight = element.clientHeight;

      window.scrollY < element.clientHeight
        ? this.setState({ stickyNav: false })
        : this.setState({
            stickyNav: true,
            headerHeight
          });
    }
  };
  /*
  handleClick: MouseEventHandler = () => {
    this.setState(prevState => ({
      ...prevState,
      showCart: !prevState.showCart
    }));
  };
 */

  handleClickOutside = () => {
    this.props.clickOutside();
    /*     if (this.state.showCart) {
      this.setState(prevState => ({
        ...prevState,
        showCart: false
      }));
    } */
  };

  render() {
    const {
      storeDisplayName,
      storeLink,
      storeAboutLink,
      storeShopLink,
      storeStylesLink,
      storeAlbumsLink,
      storeLiveLink,
      cartCount,
      timer,
      hasAlbums,
      hasLive,
      showCart,
      typeTags
    } = this.props;
    const { headerHeight, stickyNav } = this.state;

    const spacedDisplayName = storeDisplayName.replace(
      /[/\\~\-&—…]/,
      "\u200B$&\u200B"
    );

    const links: {
      url: string;
      linkText: string;
      shortText: string;
      featureCode?: string;
    }[] = [];

    links.push({
      url: storeLink,
      linkText: "Home",
      shortText: "Home"
    });

    links.push({
      url: storeAboutLink,
      linkText: "About",
      shortText: "About"
    });

    links.push({
      url: storeShopLink,
      linkText: "Shop",
      shortText: "Shop"
    });

    /*     links.push({
      url: storeStylesLink,
      linkText: "Styles",
      shortText: "Styles"
    }); */

    if (hasLive) {
      links.push({
        url: storeLiveLink,
        linkText: "Live Sales",
        shortText: "Live",
        featureCode: Features.LiveSales
      });
    }

    typeTags.forEach((tag) => {
      links.push({
        url: storeLink + `/type/${tag.id}?type=${tag.id}`,
        linkText: tag.name,
        shortText: tag.name
      });
    });

    return (
      <div
        className={`clearfix row ${Styles.container}`}
        style={stickyNav ? { height: headerHeight } : undefined}
      >
        <div className="col-12" id="js-sticky-header">
          <div className={stickyNav ? "stickyNav" : undefined}>
            <div
              className={classNames(Styles.header, {
                container: stickyNav
              })}
            >
              <div className={Styles.welcomeTitle}>Welcome to</div>
              <div className={Styles.storeLogoAndName}>
                <div className={Styles.storeLogo}>
                  <Link to={storeLink}>
                    <img
                      alt="LuLaRoe Logo"
                      src={require("../../images/LLR_Logo.svg")}
                    />
                  </Link>
                </div>
                <div className={Styles.storeName}>
                  <Link to={storeLink}>{spacedDisplayName}</Link>
                </div>
              </div>
              {/* {cartCount > 0 && (
                <Timer
                  target={timer}
                  units={["hours", "minutes", "seconds"]}
                  maxDuration={99}
                  maxDurationUnits="hours"
                >
                  <TimerActive>
                    <div className={Styles.mobileTimerContainer}>
                      <div className={Styles.mobileTimer}>
                        <div className={Styles.timeLeftText}>
                          <div className={Styles.timer}>
                            <Hours padWidth={2} />:<Minutes padWidth={2} />:
                            <Seconds padWidth={2} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TimerActive>
                </Timer>
              )} */}
              <div className={Styles.clearFix}>
                <hr className={Styles.rainbow} />
                <div className={Styles.nav}>
                  <Navigation links={links} />
                </div>
                <div
                  className={classNames({
                    [Styles.headerCartLinks]: true,
                    "has-items": cartCount > 0
                  })}
                  ref={(node) => (this.node = node)}
                >
                  {cartCount > 0 && (
                    <div
                      className={Styles.timer}
                      onClick={this.props.cartClick}
                    >
                      <Timer
                        target={timer}
                        units={["hours", "minutes", "seconds"]}
                        maxDuration={99}
                        maxDurationUnits="hours"
                      >
                        <TimerActive>
                          <Hours padWidth={2} />:<Minutes padWidth={2} />:
                          <Seconds padWidth={2} />
                        </TimerActive>
                      </Timer>
                    </div>
                  )}
                  <div
                    className={Styles.cartCountArea}
                    onClick={this.props.cartClick}
                  >
                    <span className={Styles.bagIcon}>
                      <FontAwesomeIcon icon="shopping-bag" />
                    </span>
                    <span className={Styles.cartCount}>{cartCount}</span>
                  </div>
                  {showCart && (
                    <div className={Styles.shoppingCartPlacement}>
                      <ShoppingCart handleClick={this.props.cartClick} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state) => {
  const {
    store,
    store: { alias, name },
  } = state;

  return {
    cartCount: cartCount(state),
    storeDisplayName: name || "",
    storeLink: `/${alias}`,
    storeAboutLink: `/${alias}/about`,
    storeShopLink: `/${alias}/shop`,
    storeStylesLink: `/${alias}/styles`,
    storeAlbumsLink: `/${alias}/albums`,
    storeLiveLink: `/${alias}/live`,
    storeLooksLink: `/${alias}/looks`,
    storeSearchLink: `/${alias}/search`,
    storeCartLink: `/${alias}/cart`,
    location: state.router.location.pathname,
    timer: cartExpiration(state),
    storeId: store.id || 0,
    hasAlbums: activeAlbumsSelector(state).length > 0,
    hasLive: visibleLiveEvents(state).length > 0,
    typeTags: GetActiveTypeTags(state)
  };
};

export default connect<StateProps, {}, {}, State>(mapStateToProps)(
  onClickOutside(Header)
);
