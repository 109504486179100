import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlbumList } from "../AlbumList/AlbumList";
import { PageUrls } from "../../config";
import { AlbumState } from "../../store/state/albums";
import { PatternsState } from "../../store/state/patterns";

interface OwnProps {
  albumsLink: string;
  storeAlias: string;
  list: AlbumState[];
}

type Props = OwnProps;

const maxPromotedAlbums = 12;
class HomepageAlbums extends Component<Props> {
  render() {
    const { list, albumsLink, storeAlias } = this.props;
    let filteredList = list.filter((album) => !album.isVip);

    const shortList = filteredList.slice(0, maxPromotedAlbums);
    return (
      <>
        <div className="row">
          {shortList.length > 0 ? (
            <AlbumList
              displayLimit={maxPromotedAlbums}
              padPlaceholders={6}
              albums={shortList.map((album) => ({
                ...album,
                link: PageUrls.AlbumDetailsPage(storeAlias, album.id)
              }))}
            />
          ) : null}
        </div>
        {list.length > maxPromotedAlbums ? (
          <div className="row">
            <div className="col-12 text-right shop-all-albums">
              <Link to={albumsLink} className={`btn btn-link`}>
                Shop All Albums{" "}
                <FontAwesomeIcon className="angle-icon" icon="angle-right" />
              </Link>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default HomepageAlbums;
