import { buildDiscountEstimationRequest } from "./../../utilities/checkout";
import { DiscountEstimateResponseState } from "./../../store/state/checkout/estimate";
import { Action } from "redux";
import {
  CheckoutPickupEstimateRequestState,
  CheckoutShippingEstimateRequestState,
  CheckoutEstimateResponseState,
  FailedEstimateResponseState,
} from "../../store/state/checkout/estimate";
import {
  getOrderEstimate,
  getDiscountEstimate,
} from "../../apis/checkoutEndpoints";
import { ThunkResult } from "../thunkAction";
import { buildOrderEstimationRequest } from "../../utilities/checkout";
import deepEqual from "deep-equal";

export interface EstimateRequestAction extends Action {
  type: "@@checkout/estimate/ESTIMATE_REQUEST";
  payload:
    | CheckoutPickupEstimateRequestState
    | CheckoutShippingEstimateRequestState;
}

export interface EstimateResponseFailedAction extends Action {
  type: "@@checkout/estimate/ESTIMATE_RESPONSE_FAILURE";
  payload: FailedEstimateResponseState;
}

export interface EstimateResponseSucceededAction extends Action {
  type: "@@checkout/estimate/ESTIMATE_RESPONSE_SUCCESS";
  payload: CheckoutEstimateResponseState;
}

export interface DiscountEstimateResponseAction extends Action {
  type: "@@checkout/estimate/DISCOUNT_ESTIMATE";
  payload: DiscountEstimateResponseState;
}

export type EstimateActions =
  | EstimateRequestAction
  | EstimateResponseFailedAction
  | EstimateResponseSucceededAction;

export type DiscountEstimateActions = DiscountEstimateResponseAction;

export function requestOrderEstimate(): ThunkResult<boolean, EstimateActions> {
  return async (dispatch, getState) => {
    const state = getState();
    const request = buildOrderEstimationRequest(getState());
    const { estimate } = state.checkout;

    if (estimate.response && estimate.response.isSuccess) {
      if (deepEqual(estimate.request, request) && estimate.response) {
        // We already have a result for the same request.
        // Just return true since the existing response is still valid.
        return true;
      }
    }

    if (!request) {
      dispatch({
        type: "@@checkout/estimate/ESTIMATE_RESPONSE_FAILURE",
        payload: {
          isSuccess: false,
          failureReason:
            "Unable to build an estimate request from the current state.",
        },
      });
      return false;
    }
    dispatch({
      type: "@@checkout/estimate/ESTIMATE_REQUEST",
      payload: request,
    });
    try {
      const response = await getOrderEstimate(state.store.id, request);
      if (response.isSuccess) {
        dispatch({
          type: "@@checkout/estimate/ESTIMATE_RESPONSE_SUCCESS",
          payload: response,
        });
        return true;
      } else {
        dispatch({
          type: "@@checkout/estimate/ESTIMATE_RESPONSE_FAILURE",
          payload: response,
        });
        if (response.promoCodeError !== undefined) {
          return true;
        }
        console.error("Order estimate failure", response.failureReason);
        return false;
      }
    } catch (error) {
      dispatch({
        type: "@@checkout/estimate/ESTIMATE_RESPONSE_FAILURE",
        payload: {
          isSuccess: false,
          failureReason:
            "An unexpected error occurred while estimating the order.",
        },
      });
      console.error("Order estimate failure", error);
      return false;
    }
  };
}

export function requestDiscountEstimate(): ThunkResult<
  boolean,
  DiscountEstimateResponseAction
> {
  return async (dispatch, getState) => {
    const state = getState();
    const request = buildDiscountEstimationRequest(getState());

    try {
      if (request) {
        const response = await getDiscountEstimate(state.store.id, request);
        dispatch({
          type: "@@checkout/estimate/DISCOUNT_ESTIMATE",
          payload: response,
        });
      }
      return true;
    } catch (error) {
      console.error("Discount estimate failure", error);
      return false;
    }
  };
}
