import axios from "axios";

interface ConfigResponse {
  customerRegistrationUrlTemplate: string;
  luLaRoePrivacyPolicyUrl: string;
  luLaRoeTermsUrl: string;
  invalidationDelaySeconds: number;
  storeRefreshDelaySeconds: number;
  listingsPersistWipeIntervalSeconds: number;
  googleAnalyticsId: string;
  googleAnalyticsSamplePercentage: string;
  lularoeGoogleAnalyticsId: string;
  lularoeGoogleAnalyticsSamplePercentage: string;
  lularoeAutoLinkDomains: string[];
  facebookPixelAnalyticsId: string;
  pubNubSubscribeKey: string;
  payformUrlBase: string;
  liveHeartBeatIntervalSeconds: number;
  facebookClientId: string;
}

let loadConfigPromise: Promise<ConfigResponse>;
export const getConfig = async () => {
  if (!loadConfigPromise) {
    loadConfigPromise = axios
      .get<ConfigResponse>("/api/config")
      .then((response) => response.data);
  }
  return await loadConfigPromise;
};

export const storesApiBase = "/api/stores";
export const productsApiBase = "/api/products";

export const CheckoutDisabled = false;
export const NotFoundImageUrl = [
  "https://d3o7jpqgb2hslk.cloudfront.net/assets/shoppingbag.jpg"
];
export const InvalidationDelaySeconds = async () =>
  (await getConfig()).invalidationDelaySeconds;
export const StoreRefreshDelaySeconds = async () =>
  (await getConfig()).storeRefreshDelaySeconds || 60;
export const ListingsPersistWipeIntervalSeconds = async () =>
  (await getConfig()).listingsPersistWipeIntervalSeconds || 3660;
export const LiveHeartBeatIntervalSeconds = async () =>
  (await getConfig()).liveHeartBeatIntervalSeconds || 300;

export const PubNubSubscribeKey = async () =>
  (await getConfig()).pubNubSubscribeKey;

export const FacebookClientId = async () =>
  (await getConfig()).facebookClientId;

export const GoogleAnalyticsId = async () =>
  (await getConfig()).googleAnalyticsId;
export const GoogleAnalyticsSamplePercentage = async () =>
  (await getConfig()).googleAnalyticsSamplePercentage;
export const LularoeGoogleAnalyticsId = async () =>
  (await getConfig()).lularoeGoogleAnalyticsId;
export const LularoeGoogleAnalyticsSamplePercentage = async () =>
  (await getConfig()).lularoeGoogleAnalyticsSamplePercentage;
export const LularoeAutoLinkDomains = async () =>
  (await getConfig()).lularoeAutoLinkDomains;
export const FacebookPixelAnalyticsId = async () =>
  (await getConfig()).facebookPixelAnalyticsId;

export const TypeCategoryName = "Type";
export const StyleCategoryName = "Style";
export const CollectionCategoryName = "Collection";
export const SizeCategoryName = "Size";

export const ListingsStorageKey = (storeId: number) => `Listings_${storeId}`;

export class StoresApiUrls {
  static StoreDetails = (storeId: number) => `${storesApiBase}/${storeId}`;
  static StoreDetailsByName = (storeName: string) =>
    `${storesApiBase}/byname/${storeName}`;
  static StoreLastChangeId = (storeId: number) =>
    `${storesApiBase}/${storeId}/changes/max`;
  static StoreChanges = (storeId: number, lastChangeId: string) =>
    `${storesApiBase}/${storeId}/changes?lastChangeId=${encodeURIComponent(
      lastChangeId
    )}`;
}

export class CartApiUrls {
  static CartList = (storeId: number) => `${storesApiBase}/${storeId}/cart`;
  static CartAdd = (storeId: number, listingId: number) =>
    `${storesApiBase}/${storeId}/cart/add?listingId=${listingId}`;
  static CartRemove = (storeId: number, listingId: number) =>
    `${storesApiBase}/${storeId}/cart/remove?listingId=${listingId}`;
}

export class OrderApiUrls {
  static OrderEstimate = (storeId: number) =>
    `${storesApiBase}/${storeId}/orders/estimate`;
  static OrderShippingEstimate = (storeId: number) =>
    `${storesApiBase}/${storeId}/orders/estimateshipping`;
  static OrderCreate = (storeId: number) =>
    `${storesApiBase}/${storeId}/orders/create`;
}

export class PageUrls {
  static StoreHomepage = (storeName: string) => `/${storeName}`;
  static CheckoutPage = (storeName: string) => `/${storeName}/checkout`;
  static OrderCompletePage = (storeName: string) =>
    `/${storeName}/ordercomplete`;
  static AlbumsPage = (storeName: string) => `/${storeName}/albums`;
  static AlbumDetailsPage = (storeName: string, albumId: number) =>
    `/${storeName}/albums/${albumId}`;
  static ListingsDetailsPage = (
    storeName: string,
    listingId: number,
    albumId?: number
  ) => `/${storeName}/item/${listingId}${albumId ? "?album=" + albumId : ""}`;
  static PatternDetailsPage = (
    storeName: string,
    patternId: number,
    albumId?: number
  ) => `/${storeName}/pattern/${patternId}${albumId ? "?album=" + albumId : ""}`;
  static LiveEventPage = (storeName: string, eventId: number) =>
    `/${storeName}/live/${eventId}`;
}

export class ExternalUrls {
  static CustomerRegistration = async (storeAlias: string) => {
    const urlTemplate = (await getConfig()).customerRegistrationUrlTemplate;
    return urlTemplate && urlTemplate.replace("__STORE__", storeAlias);
  };
  static LuLaRoePrivacyPolicyUrl = async () =>
    (await getConfig()).luLaRoePrivacyPolicyUrl;
  static LuLaRoeTermsUrl = async () => (await getConfig()).luLaRoeTermsUrl;
  static TokenPayFormPath = async (
    storeId: number,
    update: boolean,
    nuveiRequestId: string | undefined
  ) => {
    let urlBase = (await getConfig()).payformUrlBase;
    return `${urlBase}?storeId=${storeId}&platform=Shopping${
      nuveiRequestId ? "&referenceGuid=" + nuveiRequestId : null
    }&update=${update}`;
  };
}
