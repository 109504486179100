import React, { Component, createRef  } from "react";
import { Link } from "react-router-dom";
import { Overlay, Popover } from "react-bootstrap/";
import Styles from "./PatternListItem.module.scss";
import { ProductManagerContext } from "../../ProductManager/ProductManagerContext";
import AddToBagModal from './AddToBagModal';
import { PatternItem, sortPatternListingsBySize } from "../../../utilities/patterns";
import {
  AddListingImpressionsToScope,
  TrackListClick,
  TrackItemListView
} from "../../../analytics";
import { createAggregateDebounce } from "../../../utilities/aggregateDebounce";
import { Formatter } from '../../../Formatter';
import { TagCategoryDefs } from '../../../enums/TagCategoryDefs';

interface Props {
  item: PatternItem;
  listName: string;
  albumId?: number;
  sizeChartOpen: boolean;
  openSizeChart: (typeId: number) => void;
}

interface State {
  showImage: boolean;
  showAddToBagModal: boolean;
}

interface ListingViewsByList {
  [listName: string]: PatternItem[];
}

const placeholderImage = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

class PatternListItem extends Component<Props, State> {
  state = {
    showImage: false,
    showAddToBagModal: false,
  };

  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  unmounting = false;
  observer?: IntersectionObserver;
  imageRef = createRef<HTMLDivElement>();
  overlayTarget = createRef<HTMLButtonElement>();

  componentDidMount() {
    this.createVisibilityObserver();
    TrackItemListView(this.props.listName);
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.destroyVisibilityObserver();
  }

  componentDidUpdate() {
    const { listingDetails } = this.props.item;
    const { showAddToBagModal } = this.state;

    const listingsSizes = sortPatternListingsBySize(listingDetails);

    if (showAddToBagModal && listingsSizes.length <= 0) {
      this.closeAddToBagModal();
    }
  }

  updateVisibility: IntersectionObserverCallback = (entries) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      this.setState((state) => ({ ...state, showImage: true }));
      this.destroyVisibilityObserver();
    }
  };

  createVisibilityObserver = () => {
    if (this.imageRef.current) {
      this.observer = new IntersectionObserver(this.updateVisibility, {
        rootMargin: "400px"
      });
      this.observer.observe(this.imageRef.current);
      this.updateVisibility(this.observer.takeRecords(), this.observer);
    }
  };

  destroyVisibilityObserver = () => {
    this.observer && this.observer.disconnect();
  };

  closeAddToBagModal = () => {
    const { showAddToBagModal } = this.state;

    if (showAddToBagModal) {
      this.setState((prevState) => ({
        ...prevState,
        showAddToBagModal: false
      }));
    }
  };

  render() {
    const {
      imageDetails,
      styleDisplayName,
      listingPatternId,
      listingDetails,
      maximumPrice,
      minimumPrice,
      maxRetailPrice,
      minRetailPrice,
      displayPriceRange,
      detailsUrl,
      tagDetails,
    } = this.props.item;
    const { listName, albumId, sizeChartOpen, openSizeChart } = this.props;
    const { showAddToBagModal, showImage } = this.state;

    const primaryImageUrl = imageDetails.find((imageDetail) => (
      imageDetail.isPrimary
    ))?.imageUrl || imageDetails[0]?.imageUrl;
    const patternStyle = tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style)?.id;

    let listingsSizes = sortPatternListingsBySize(listingDetails);

    let imageUrl: string;
    if (showImage) {
      imageUrl = primaryImageUrl || placeholderImage;
    } else {
      imageUrl = placeholderImage;
    }

    return (
      <>
        <div
          className={Styles.itemCard}
          style={{ flex: 1, marginBottom: "5px" }}
        >
          <Link
            to={`${detailsUrl}${albumId ? "?album=" + albumId : ""}`}
            className={Styles.view_details}
            /*             onClick={() => {
              TrackListClick(this.props.item, listName);
            }} */
          >
            <div className={Styles.itemImageArea} ref={this.imageRef}>
              <img
                alt={styleDisplayName}
                className={Styles.itemImage}
                src={imageUrl}
              />
            </div>
          </Link>
          <div className={`clearfix ${Styles.itemCardText}`}>
            <div className={Styles.description}>
              <div className={Styles.subtitle}>
                {listingsSizes
                  .map((listing) => listing.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name)
                  .join(', ')
                }
              </div>
            </div>
            <div className={Styles.price}>
              <div className={Styles.originalPrice}>
                {(minimumPrice < minRetailPrice || maximumPrice < maxRetailPrice) && (
                  <>
                    <span>
                      {minimumPrice < minRetailPrice ? Formatter.format(minRetailPrice) : Formatter.format(minimumPrice)}
                    </span>
                    {(maxRetailPrice !== minRetailPrice) && (
                      <>
                        {'\u2014'}
                        <span>
                          {maximumPrice < maxRetailPrice ? Formatter.format(maxRetailPrice) : Formatter.format(maximumPrice)}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              {displayPriceRange}
            </div>
            <div className={Styles.title}>{styleDisplayName}</div>
          </div>
        </div>
        {
          listingsSizes && listingsSizes.length > 0 ? (
            <button
              ref={this.overlayTarget}
              className={`btn ${Styles.add_to_bag}`}
              onClick={() => {
                this.setState((prevState) => (
                  {
                    ...prevState,
                    showAddToBagModal: true,
                  }
                ))
              }}
            >
              Add To My Bag
            </button>
          ) : (
            <button disabled className={`btn ${Styles.disabled}`}>In Your Bag</button>
          )
        }
        <Overlay
          show={showAddToBagModal}
          placement="top"
          target={this.overlayTarget}
        >
          <Popover className={Styles.add_to_bag_popover} id="popover-positioned-top">
            <AddToBagModal
              albumId={albumId}
              listName={listName}
              patternStyle={patternStyle}
              patternListings={listingsSizes}
              displayPriceRange={displayPriceRange}
              sizeChartOpen={sizeChartOpen}
              openSizeChart={openSizeChart}
              closeOverlay={this.closeAddToBagModal}
            />
          </Popover>
        </Overlay>
        <div className={showAddToBagModal ? Styles.overlayOpen : ''}></div>
      </>
    );
  }
}

export default PatternListItem;
