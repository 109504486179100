import React, { useRef, useState } from "react";
import { func, object, string, bool, number } from "prop-types";
import { Overlay, Popover } from 'react-bootstrap';
import ImageCover from "../ImageCover";
import { Formatter } from "../../Formatter";
import AddToBagModal from '../../components/ProductList/PatternListItem/AddToBagModal';
import { sortPatternListingsBySize } from '../../utilities/patterns';
import Styles from "./AlbumItem.module.scss";
import { TagCategoryDefs } from "enums/TagCategoryDefs";

const propTypes = {
  eventName: string,
  eventId: number,
  className: string,
  liveProduct: object.isRequired,
  onAddItemToCart: func.isRequired,
  onRemoveItemFromCart: func,
  showCartActions: bool,
  sizeChartOpen: bool,
  openSizeChart: func
};

const defaultProps = {
  showCartActions: false
};

const AlbumItem = ({
  eventName,
  eventId,
  liveProduct,
  className,
  showCartActions,
  onAddItemToCart,
  onRemoveItemFromCart,
  sizeChartOpen,
  openSizeChart
}) => {
  const ClaimStatus = {
    Unclaimed: 'Unclaimed',
    ClaimedByYou: 'ClaimedByYou',
    Claimed: 'Claimed',
  };
  const overlayTarget = useRef();
  const [showAddToBagModal, setShowAddToBagModal] = useState(false);

  const sortedListing = sortPatternListingsBySize(liveProduct.listingDetails);

  const sizes = sortedListing
    .map(listing => listing.tagDetails
      .find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name
    )
    .filter((size) => size);

  const productImageUrl = liveProduct.imageDetails.find(image => image.isPrimary).imageUrl;

  const isSold = liveProduct.listingDetails.length <= 0;

  const productQuantity = liveProduct.listingDetails.reduce((acc, curr) => acc + curr.quantity, 0);

  const patternStyle = liveProduct.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style);

  let productClaimStatus;
  liveProduct.listingDetails.forEach(listing => (
    listing.claimBySessions.some(claim => {
      switch (claim.claimStatus) {
        case ClaimStatus.Unclaimed:
          productClaimStatus = claim.claimStatus;
          return true;
        case ClaimStatus.ClaimedByYou:
          productClaimStatus = claim.claimStatus;
          break;
        case ClaimStatus.Claimed:
          if (productClaimStatus !== ClaimStatus.ClaimedByYou) productClaimStatus = claim.claimStatus;
          break;
      }

      return false;
    })
  ));

  return (
    <div className={className}>
      <div className={Styles.live_inventory_wrapper}>
        <div className={Styles.live_inventory_img_wrapper}>
          <ImageCover
            src={productImageUrl}
            alt={liveProduct.styleDisplayName}
            className={Styles.live_inventory_img}
          />
          <div className={`${Styles.live_inventory_interaction} d-block`}>
            {/* TODO: Sold shows at claimed for people on page. Refresh shows sold. Pubnub update for sold? */}
            {isSold ? (
              <button className={`btn btn-block ${Styles.disabled}`} disabled>
                Sold
              </button>
            ) : (
              (() => {
                if (!showCartActions) {
                  return null;
                }
                if (productQuantity <= 0) {
                  switch (productClaimStatus) {
                    case ClaimStatus.Unclaimed:
                      return (
                        <button
                          ref={overlayTarget}
                          className={`btn btn-block ${Styles.add_to_bag}`}
                          onClick={() => setShowAddToBagModal(!showAddToBagModal)}
                        >
                          Add To Bag
                        </button>
                      );
                    case ClaimStatus.ClaimedByYou:
                      return (
                        <button
                          className={`btn btn-block ${Styles.disabled}`}
                          disabled
                        >
                          In Your Bag
                        </button>
                      );
                    case ClaimStatus.Claimed:
                      return (
                        <button
                          className={`btn btn-block ${Styles.disabled}`}
                          disabled
                        >
                          Claimed
                        </button>
                      );
                    default:
                      console.error(
                        `Item ${liveProduct.id || liveProduct.listingPatternId} claimStatus was something unexpected: ${productClaimStatus}`
                      );
                      return null;
                  }
                } else {
                  return (
                    <button
                      ref={overlayTarget}
                      className={`btn btn-block ${Styles.add_to_bag}`}
                      onClick={() => setShowAddToBagModal(!showAddToBagModal)}
                    >
                      Add To Bag
                    </button>
                  );
                }
              })()
            )}
          </div>
        </div>
        <div className={Styles.live_inventory_info}>
          <div className={`${Styles.live_inventory_name} clearfix`}>
            {liveProduct.styleDisplayName}
            <span className={Styles.live_inventory_price}>
              {liveProduct.displayPriceRange}
            </span>
          </div>
          <div className={`${Styles.live_inventory_size} clearfix`}>
            {sizes.join(', ')}
            <span className={Styles.live_inventory_number}>
              {liveProduct.hangerCode}
            </span>
          </div>
        </div>
      </div>
      <Overlay
        show={showAddToBagModal}
        placement="top"
        target={overlayTarget}
      >
        <Popover className={Styles.add_to_bag_popover} id="popover-positioned-top">
          <AddToBagModal
            eventId={eventId}
            listName={eventName}
            patternStyle={patternStyle?.id}
            patternListings={sortedListing}
            displayPriceRange={liveProduct.displayPriceRange}
            sizeChartOpen={sizeChartOpen}
            openSizeChart={openSizeChart}
            closeOverlay={() => setShowAddToBagModal(false)}
          />
        </Popover>
      </Overlay>
    </div>
  );
};

AlbumItem.propTypes = propTypes;
AlbumItem.defaultProps = defaultProps;

export default AlbumItem;
