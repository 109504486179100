import GoogleAnalytics from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ListingPatternModel, PatternListingDetails, ListingPatternTagDetails } from "./apis/listingsEndpoints";
import { CartListingType } from './utilities/cart';
import { TagCategoryDefs } from './enums/TagCategoryDefs';
import { UaEventOptions } from "react-ga4/types/ga4";
import { track } from "react-facebook-pixel";
import { CheckoutBillingInfoState } from "store/state/checkout/billing";

interface PixelOptions {
  autoConfig: boolean;
  debug: boolean;
}

interface Ga4Event {
  send_to: string[];
  currency: string;
  value: number;
  items: ProductDetails[];
}

// Define an interface for each specific event type
interface AddToCartEvent extends Ga4Event {
  item_list_name?: string;
}

interface RemoveFromCartEvent extends Ga4Event { }
interface ViewItemEvent extends Ga4Event { }
interface CheckoutInfoEvent extends Ga4Event {
  coupon?: string;
  shipping_tier?: string;
  payment_type?: string;
}
interface AddShippingInfoEvent extends CheckoutInfoEvent { }
interface AddPaymentInfoEvent extends CheckoutInfoEvent { }
interface PurchaseEvent extends Ga4Event {
  transaction_id: number;
  shipping: number;
  tax: number;
}

// Define a type alias for the union of all event types
type Ga4EventType = AddToCartEvent |
  RemoveFromCartEvent |
  ViewItemEvent |
  AddShippingInfoEvent |
  AddPaymentInfoEvent |
  PurchaseEvent;


interface ProductDetails {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_category?: string;
  item_variant?: string;
  price?: number;
  quantity?: number;
}

// Define an enum for the tracker keys
export enum GoogleAnalyticsTrackerKey {
  LuLaRoe = "lularoetracker",
  Rs = "rstracker",
  Retailer = "retailertracker"
}

// Define an enum for the event names enum Ga4EventName
enum Ga4EventName {
  AddToCart = "add_to_cart", // This event is triggered when a user adds a product to their shopping cart on the website. 
  RemoveFromCart = "remove_from_cart", // This event is triggered when a user removes a product from their shopping cart on the website.
  ViewItem = "view_item", // This event is triggered when a user views the details of a product on the website. 
  AddShippingInfo = "add_shipping_info", // This event is triggered when a user enters their shipping address in the checkout process on the website. 
  AddPaymentInfo = "add_payment_info", // This event is triggered when a user enters their payment method in the checkout process on the website. 
  Purchase = "purchase", // This event is triggered when a user completes a purchase transaction on the website.
  SelectItem = "select_item" // This event is triggered when a user selects a product from a list of products on the website.
}

// Define a constant variable for the currency code
const CURRENCY_CODE = "USD";
const Trackers = new Map<GoogleAnalyticsTrackerKey, string>();
const Pixels: string[] = [];

// SetUpTracker initializes a Google Analytics tracker with the given tracker name,
// property ID and other options. It also adds the tracker name to an array of trackers
// and requires the enhanced ecommerce plugin for the tracker. It sets the currency code
// to USD for the tracker as well.
// Parameters:
// - trackerName: the name of the tracker to be set up
// - propertyId: the tracking ID of the Google Analytics property
// - otherOptions: any other options to be passed to the Google Analytics initialization (optional)
const SetUpTracker = (
  trackerKey: GoogleAnalyticsTrackerKey,
  propertyId: string,
  otherOptions: any = {},
  autoLinkDomains: string[] = []
) => {
  if (Trackers.has(trackerKey)) {
    return;
  }
  Trackers.set(trackerKey, propertyId);

  // disable auto page view
  GoogleAnalytics.initialize(
    [
      {
        trackingId: propertyId,
        gaOptions: {
          ...otherOptions
        },
        gtagOptions: {
          send_page_view: false,
          linker: {
            domains: autoLinkDomains
          }
        }
      }
    ]
  );
};

export const SetUpPixelProperty = (
  propertyId: string,
  options?: PixelOptions
) => {
  Pixels.push(propertyId);
  ReactPixel.init(propertyId, options);
};

export const SetUpAnalyticsProperty = (
  trackerKey: GoogleAnalyticsTrackerKey,
  id: string,
  otherOptions: any = {}
) => {
  SetUpTracker(trackerKey, id, otherOptions);
};

export const TearDownPixelProperty = (propertyId: string) => {
  if (Pixels.includes(propertyId)) {
    Pixels.splice(Pixels.indexOf(propertyId), 1);
  }
};

export const TearDownAnalyticsProperty = (trackerKey: GoogleAnalyticsTrackerKey) => {
  if (Trackers.has(trackerKey)) {
    Trackers.delete(trackerKey);
  }
};

const replaceSlashes = (input: string) => input.replace("/", "&");

const normalizeTagInfo = (listingTags: ListingPatternTagDetails[], patternTags: ListingPatternTagDetails[]) => {
  const fashion = patternTags.find((tag) => tag.tagCategoryId === TagCategoryDefs.Fashion)?.name;
  const type = patternTags.find((tag) => tag.tagCategoryId === TagCategoryDefs.Type)?.name;
  const style = patternTags.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style)?.name;
  const collection = patternTags.find((tag) => tag.tagCategoryId === TagCategoryDefs.Collection)?.name;
  const size = listingTags.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name;

  return {
    fashion: replaceSlashes(fashion || "Other Fashion"),
    type: replaceSlashes(type || "Other Type"),
    style: replaceSlashes(style || "Other Style"),
    collection: replaceSlashes(collection || ""),
    size: replaceSlashes(size || "Other Size")
  };
}

const trackGa4Event = (eventName: Ga4EventName, eventParams: Ga4EventType) => {
  GoogleAnalytics.gtag("event", eventName, eventParams);
};

const listingAnalyticsCache = new WeakMap();

// Builds an analytics object for a list of listings based on their tag details and style display name
// Normalizes the tag information and constructs the category and variant fields for each listing 
// Caches the analytics objects in a weak map to avoid duplication
// Parameters:
// - listing: an array of listing objects of the products
// - styleDisplayName: the name of the style to be displayed
// - patternTags: an array of tag details for the pattern
// Returns: an analytics object with the currency, value and items properties for one of the ga4 event types
const BuildListingAnalyticsObject = (
  listings: PatternListingDetails[]): Ga4Event => {
  let items = listings.map((listing, index) => {
    // Destructure the listing properties
    const { listingId, salePrice, quantity, tagDetails, styleDisplayName, patternTagDetails } = listing;
    // Normalize the tag information
    const { fashion, type, style, collection, size } = normalizeTagInfo(
      tagDetails,
      patternTagDetails
    );
    // Create an item object with the required parameters for ga4
    const item: ProductDetails = {
      item_id: `item_${listingId}`,
      item_name: collection ? `${collection} ${style}` : style,
      item_category: `${fashion}/${type}/${style}/${size}`,
      item_variant: collection,
      price: listing.salePrice,
      quantity: 1,
      index: index + 1,
    };

    return item;
  });
  // Create an analytics object with the currency, value and items properties
  const result = {
    send_to: Array.from(Trackers.values()),
    currency: CURRENCY_CODE,
    value: items.reduce((total, i) => total + (i.price ?? 0) * (i.quantity ?? 0), 0),
    items: items,
  };

  return result;
};

const listingPixelObjectCache = new WeakMap();
const BuildPixelListingObject = (listing: PatternListingDetails, patternTags: ListingPatternTagDetails[]) => {
  if (listingPixelObjectCache.has(listing)) {
    return listingPixelObjectCache.get(listing);
  } else {
    const { fashion, type, style, collection, size } = normalizeTagInfo(listing.tagDetails, patternTags);
    const result = {
      id: `item_${listing.listingId}`,
      type,
      fashion,
      style,
      collection,
      size,
      item_price: listing.salePrice,
      quantity: listing.quantity,
    };
    listingPixelObjectCache.set(listing, result);
    return result;
  }
};

export const AddListingImpressionsToScope = (listings: PatternListingDetails[]) => {
  // // This is too chatty for now
  // console.log(listings.length);
  // listings.forEach(listing => {
  //   console.log(listing);
  //   currentImpressions.push(BuildListingAnalyticsObject(listing));
  // });
};

const ApplyScopedImpressions = (listName?: string) => {
  // // This is too much volume.
  // if (currentImpressions.length > 0) {
  //   const impressions = currentImpressions.splice(0, currentImpressions.length);
  //   impressions.slice(0, 50).forEach((detail, index) => {
  //     callTrackers("ec:addImpression", {
  //       ...detail,
  //       list: listName,
  //       position: index + 1
  //     });
  //   });
  // }
};

// This event isn't really used for anything in enhanced ecommerce on its own, but
// impressions are not sent until we send the next GA event, so this triggers that.
// If they ever add a "list view" action, that would make more sense here.
export const TrackItemListView = (listName: string) => {
  // // This is too much volume.
  // Pixels.forEach(pixelId => {
  //   ReactPixel.fbq("trackSingle", pixelId, "ViewContent", {
  //     content_type: "product_group",
  //     content_name: listName
  //   });
  // });
  // ApplyScopedImpressions(listName);
  // TrackEvent({
  //   category: "engagement",
  //   action: "view_item_list",
  //   label: `${listName}`,
  //   nonInteraction: true
  // });
};

export const TrackItemView = (pattern: ListingPatternModel, listName: string) => {
  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "ViewContent", {
      content_type: "product",
      contents: pattern.listingDetails.map((listing) => BuildPixelListingObject(listing, pattern.tagDetails))
    });
  });
  ApplyScopedImpressions(listName);
  trackGa4Event(Ga4EventName.ViewItem, BuildListingAnalyticsObject(pattern.listingDetails));
  TrackEvent({
    category: "engagement",
    action: "view_item",
    label: `${pattern.listingPatternId}`,
    nonInteraction: true
  });
};

export const TrackAddToCart = (listing: PatternListingDetails, listName: string, styleDisplayName: string, patternTags: ListingPatternTagDetails[]) => {
  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "AddToCart", {
      content_type: "product",
      contents: [BuildPixelListingObject(listing, patternTags)]
    });
  });
  ApplyScopedImpressions(listName);
  trackGa4Event(Ga4EventName.AddToCart, {
    ...BuildListingAnalyticsObject([
      {
        ...listing,
        styleDisplayName: styleDisplayName,
        patternTagDetails: patternTags
      }]), item_list_name: listName
  });
  TrackEvent({
    category: "ecommerce",
    action: "add_to_cart",
    label: `${listing.listingId}`
  });
};

// TrackListClick measures the user engagement and interest in the products that are displayed in a list on the website
// It can help to understand which products are more appealing or attractive to the users and how they interact with them
// Parameters:
// - listing: the listing object of the product that was clicked
// - listName: the name of the list where the product was displayed
// - styleDisplayName: the name of the style to be displayed
// - patternTags: an array of tag details for the pattern
export const TrackListClick = async (
  listing: PatternListingDetails,
  listName: string,
  styleDisplayName: string,
  patternTags: ListingPatternTagDetails[],
) => {
  ApplyScopedImpressions("Shop The Look");
  trackGa4Event(Ga4EventName.SelectItem, BuildListingAnalyticsObject([listing]));
  // TrackEvent({
  //   category: "engagement",
  //   action: "list_click",
  //   label: `${listName}`
  // });
};

export const TrackRemoveFromCart = (listing: PatternListingDetails, styleDisplayName: string, patternTags: ListingPatternTagDetails[]) => {
  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "RemoveFromCart", {
      content_type: "product",
      contents: [BuildPixelListingObject(listing, patternTags)]
    });
  });
  trackGa4Event(Ga4EventName.RemoveFromCart, BuildListingAnalyticsObject([
    {
      ...listing,
      styleDisplayName: styleDisplayName,
      patternTagDetails: patternTags
    }]));
  TrackEvent({
    category: "ecommerce",
    action: "remove_from_cart",
    label: `${listing.listingId}`
  });
};

export const TrackCheckoutAddressForm = (cartListings: CartListingType[]) => {
  const fbContents = [...cartListings.map((listing) => BuildPixelListingObject(listing, listing.patternTagDetails))];

  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "InitiateCheckout", {
      contents: fbContents,
      content_type: "product"
    });
  });

  trackGa4Event(Ga4EventName.AddShippingInfo, BuildListingAnalyticsObject(cartListings));

  TrackEvent({
    category: "ecommerce",
    action: "begin_checkout"
  });
};

export const TrackCheckoutAddPayment = (cartListings: CartListingType[]) => {
  const fbContents = [...cartListings.map((listing) => BuildPixelListingObject(listing, listing.patternTagDetails))];

  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "AddPaymentInfo", {
      contents: fbContents,
      content_type: "product"
    });
  });

  trackGa4Event(Ga4EventName.AddPaymentInfo, BuildListingAnalyticsObject(cartListings));
  TrackEvent({
    category: "ecommerce",
    action: "add_payment_info"
  });
};

export const TrackCheckoutComplete = (
  orderListings: CartListingType[],
  orderId: number,
  storeId: number,
  total: number,
  tax: number,
  shipping: number
) => {

  const fbContents = [...orderListings.map((listing) => BuildPixelListingObject(listing, listing.patternTagDetails))];

  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "Purchase", {
      contents: fbContents,
      content_type: "product",
      currency: CURRENCY_CODE,
      value: total,
      tax,
      shipping
    });
  });

  // Call the BuildListingAnalyticsObject function with the whole orderListings array
  const parameter = BuildListingAnalyticsObject(orderListings);

  // Set the affiliation property of each item to the store id
  parameter.items.forEach(y => y.affiliation = storeId ? storeId.toString() : '');

  // Call the trackGa4Event function with the name “Purchase” and the parameter object
  trackGa4Event(Ga4EventName.Purchase, {
    ...parameter,
    transaction_id: orderId,
    shipping,
    tax
  });

  TrackEvent({
    category: "ecommerce",
    action: "purchase"
  });
};

export const TrackEvent = (
  args: UaEventOptions,
  includeFacebook: boolean = false
) => {
  ApplyScopedImpressions();
  GoogleAnalytics.event(args, {
    send_to: Array.from(Trackers.values())
  });
  includeFacebook &&
    Pixels.forEach((pixelId) => {
      ReactPixel.fbq("trackSingle", pixelId, args.action, args);
    });
};

// The TrackPage function measures how users interact with different pages on the website
// It sends pageview data to Google Analytics and Facebook Pixel for each tracker and pixel that has been set up
// It also records any product impressions that have been added to the current session
// This function is called whenever there's a route change, which means when a user navigates to a different page on the website
export const TrackPage = (path: string, title?: string) => {
  // Record any product impressions that have been added to the current session
  ApplyScopedImpressions();

  GoogleAnalytics.set(
    {
      page: path,
      title,
      send_to: Array.from(Trackers.values())
    }
  );

  GoogleAnalytics.send({
    hitType: "pageview", page: path, title: title,
    send_to: Array.from(Trackers.values())
  })

  Pixels.forEach((pixelId) => {
    ReactPixel.fbq("trackSingle", pixelId, "PageView");
  });
};
