import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import { PageUrls, ExternalUrls } from "../../config";
import {
  TrackCheckoutAddPayment,
  TrackCheckoutAddressForm
} from "../../analytics";
import {
  CartListingType,
  getClaimedListings,
  cartSubtotal,
  cartCount
} from "../../utilities/cart";
import {
  CheckoutCustomerDataState,
  CheckoutCustomerInfoState
} from "../../store/state/checkout/customer";
import {
  CheckoutDeliveryDataState,
  CheckoutDeliveryInfoState
} from "../../store/state/checkout/delivery";
import {
  CheckoutBillingDataState,
  CheckoutBillingInfoState
} from "../../store/state/checkout/billing";
import { State } from "../../store/state";
import {
  updateCustomer,
  markCustomerEditing,
  requestShippingAmount,
  requestOrderEstimate,
  requestPlaceOrder,
  markDeliveryEditing,
  updateDelivery,
  markBillingEditing,
  updateBilling,
  markCustomerFailure
} from "../../actions/checkout";
import { CheckoutEstimateState } from "../../store/state/checkout/estimate";

import {
  CustomerForm,
  CustomerFormSummary
} from "../../components/Checkout/CustomerForm";
import {
  DeliveryForm,
  DeliveryFormSummary
} from "../../components/Checkout/DeliveryForm";
import {
  BillingForm,
  BillingFormSummary
} from "../../components/Checkout/BillingForm";
import { PaymentFormSummary } from "../../components/Checkout/BillingForm/PaymentFormSummary";
import LoadingButton from "../../components/Buttons";
import Styles from "./CheckoutPage.module.scss";
import { PickupDetailsState } from "../../store/state/store";
import { EstimatedOrderSummary } from "../../components/Checkout/EstimatedOrderSummary/EstimatedOrderSummary";
import OhSnap from "../../components/Checkout/OhSnap/OhSnap";
import { Formatter } from "../../Formatter";
import LoadingSpinner from "../../components/LoadingSpinner";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { clearBillingState } from "../../actions/checkout/billing";
import {
  updateStatus,
  updateCustomerStatus,
  updateErrorStatus
} from "../../apis/statusEndpoints";
import { push } from "connected-react-router";
import classnames from "classnames";
import { TagCategoryDefs } from '../../enums/TagCategoryDefs';
import { ClaimStatus, DeliveryMethod } from "../../enums";
import { checkPromoCode } from "../../apis/checkoutEndpoints";
import {
  PromoCodeFailureReasons,
  PromoCodeCheckResponse,
  PromoCodeDetails,
  PromoCodeDiscountTypes
} from "../../apis/promoCodeModels";
import { DateTime } from "luxon";
import uuid from "uuid";
import { CheckoutStatus } from "../../enums/CheckoutStatus";
import { requestDiscountEstimate } from "actions/checkout/estimate";

interface StateProps {
  storeId: number;
  customer: CheckoutCustomerInfoState;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  facebookId?: string;
  delivery: CheckoutDeliveryInfoState;
  billing: CheckoutBillingInfoState;
  shippingAmount?: number;
  estimatedShippingAmount?: number;
  estimate: CheckoutEstimateState;
  readyToBeginCheckout: boolean;
  cartListings: CartListingType[];
  homepagePath: string;
  orderCompletePath: string;
  shippingPolicy: string;
  returnsPolicy: string;
  readyToPay: boolean;
  pickupDetails: PickupDetailsState;
  orderFailed: boolean;
  avsFailed: boolean;
  showOhSnap: boolean;
  readyForEstimate: boolean;
  readyForDiscountEstimate: boolean;
  cartCount: number;
  cartSum: number;
  freeOrder: boolean;
  discountEstimate?: number;
}

interface ComponentState {
  showModal: boolean;
  modalContents?: string;
  updatingShipping: boolean;
  updatingEstimate: boolean;
  updatingDiscountEstimate: boolean;
  estimateRetries: number;
  discountEstimateRetries: number;
  orderPending: boolean;
  llrPrivacyUrl: string;
  llrTermsUrl: string;
  offer?: PromoCodeDetails;
  hasToken: boolean;
  nuveiRequestId?: string;
  payFormUrl: string;
}

type Props = StateProps & ThunkDispatchProp & RouteComponentProps;

class CheckoutPage extends Component<Props, ComponentState> {
  state: ComponentState = {
    showModal: false,
    updatingEstimate: false,
    updatingDiscountEstimate: false,
    updatingShipping: false,
    orderPending: false,
    estimateRetries: 0,
    discountEstimateRetries: 0,
    llrPrivacyUrl: "",
    llrTermsUrl: "",
    hasToken: false,
    nuveiRequestId: undefined,
    payFormUrl: ""
  };

  unmounting = false;

  markNextUnenteredForEdit() {
    const { customer, delivery, billing, dispatch, cartListings } = this.props;

    if (!customer.data) {
      customer.editing || dispatch(markCustomerEditing());
    } else if (!delivery.data) {
      delivery.editing || dispatch(markDeliveryEditing());
    } else if (!billing.data) {
      billing.editing || dispatch(markBillingEditing(true, true));
      TrackCheckoutAddPayment(cartListings);
    }
  }

  handleSaveCustomer = async (customer: CheckoutCustomerDataState) => {
    const { delivery, facebookId } = this.props;

    this.props.dispatch(updateCustomer(customer));

    const promoSuccess = await this.validatePromoCode();

    try {
      await updateStatus(this.props.storeId, {
        checkoutStatus: CheckoutStatus.CustomerInformationAdded
      });
    } catch (error) {
      console.log("Error updating checkout status");
    }

    try {
      await updateCustomerStatus(this.props.storeId, {
        firstName: customer.firstName,
        lastName: customer.lastName,
        emailAddress: customer.email,
        facebookId: facebookId ? facebookId : ""
      });
    } catch (error) {
      console.log("Error updating customer checkout information");
    }

    if (promoSuccess) {
      if (!delivery.data) {
        this.props.dispatch(markDeliveryEditing());
      } else {
        if (delivery.data.sameNameAsCustomer) {
          this.props.dispatch(
            updateDelivery({
              ...delivery.data,
              firstName: customer.firstName,
              lastName: customer.lastName
            })
          );
        } else {
          if (
            delivery.data.firstName === customer.firstName &&
            delivery.data.lastName === customer.lastName
          ) {
            this.props.dispatch(
              updateDelivery({
                ...delivery.data,
                sameNameAsCustomer: true
              })
            );
          }
        }
      }
    }
  };

  validatePromoCode = async () => {
    const { customer, delivery, storeId, cartSum, cartListings } = this.props;

    if (customer.data && customer.data.promoCode) {
      const promoResponse = await checkPromoCode(storeId, {
        customerEmail: customer.data.email,
        promoCode: customer.data.promoCode,
        isShipping: delivery.data
          ? delivery.data.method === DeliveryMethod.Shipping
          : true,
        subtotal: cartSum,
        utcOffset: new Date().getTimezoneOffset(),
        productIds: cartListings.map((x) => x.productId)
      });
      if (!promoResponse.isValid) {
        this.setState({ offer: undefined });
        const errorString = this.getPromoFailureMessage(promoResponse);
        try {
          await updateErrorStatus(this.props.storeId, {
            errorMessage: errorString ? errorString : ""
          });
        } catch (error) {
          console.log("Error updating session error status");
        }
        this.props.dispatch(
          markCustomerFailure({
            promoCode: errorString
          })
        );
        return false;
      } else {
        this.setState({ offer: promoResponse.promoDetails });
        const newCust =
          customer.data.promoCode === promoResponse.promoDetails.code
            ? customer.data
            : { ...customer.data, promoCode: promoResponse.promoDetails.code };
        this.props.dispatch(updateCustomer(newCust));
      }
    } else {
      this.setState({ offer: undefined });
    }
    return true;
  };

  getPromoFailureMessage = (response: PromoCodeCheckResponse) => {
    if (response.isValid) {
      return undefined;
    }
    let errorString;
    if (response.failureCodes.includes(PromoCodeFailureReasons.NotFound)) {
      errorString =
        "This promo code is not valid; please reenter the code or contact the retailer if you need help.";
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.Revoked)
    ) {
      errorString =
        "This promo code has been revoked by the retailer; please contact the retailer if you need help.";
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.NotYetValid)
    ) {
      const starting = response.promoDetails && response.promoDetails.startDate;
      if (starting) {
        const startingDateTime = DateTime.fromISO(starting, { zone: "utc" });
        const dateString = startingDateTime.toLocaleString({
          weekday: "long",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        });
        errorString = `This promo code is not valid until ${dateString}`;
      } else {
        errorString = "This promo code is not valid yet.";
      }
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.NoLongerValid)
    ) {
      errorString = "This promo code has expired and is no longer valid.";
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.UsesExhausted)
    ) {
      errorString = "This promo code has been used and is no longer valid.";
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.MinTotalNotMet)
    ) {
      const minTotal =
        response.promoDetails && response.promoDetails.minOrderAmount;
      if (minTotal) {
        errorString = `The total amount of the order before tax does not meet the minimum required amount (${Formatter.format(
          minTotal
        )}) for this promo code.`;
      } else {
        errorString = `The total amount of the order before tax does not meet the minimum required amount for this promo code.`;
      }
    } else if (
      response.failureCodes.includes(
        PromoCodeFailureReasons.ShippingNotApplicable
      )
    ) {
      errorString =
        "This promo code is for free shipping only and not applicable for pick-up orders.";
    } else if (
      response.failureCodes.includes(PromoCodeFailureReasons.ItemsNotApplicable)
    ) {
      errorString = `This promo code does not apply to any items in your cart.`;
    }
    return errorString;
  };

  handleSaveDelivery = async (delivery: CheckoutDeliveryDataState) => {
    const { billing, cartListings } = this.props;
    this.props.dispatch(updateDelivery(delivery));
    const promoResult = await this.validatePromoCode();
    if (!promoResult) {
      return;
    } // Short trip if our new delivery info broke our promo code
    TrackCheckoutAddressForm(cartListings);
    if (!billing.data) {
      this.props.dispatch(markBillingEditing(true, true));
    } else {
      if (delivery.method === "SHIPPING") {
        if (billing.data.sameAddressAsShipping) {
          this.props.dispatch(
            updateBilling({
              ...billing.data,
              line1: delivery.line1,
              line2: delivery.line2,
              city: delivery.city,
              state: delivery.state,
              zipCode: delivery.zipCode
            })
          );
        } else {
          if (
            delivery.line1 === billing.data.line1 &&
            delivery.line2 === billing.data.line2 &&
            delivery.city === billing.data.city &&
            delivery.state === billing.data.state &&
            delivery.zipCode === billing.data.zipCode
          ) {
            this.props.dispatch(
              updateBilling({
                ...billing.data,
                sameAddressAsShipping: true
              })
            );
          }
        }
      } else {
        this.props.dispatch(
          updateBilling({
            ...billing.data,
            sameAddressAsShipping: false
          })
        );
      }
      try {
        await updateStatus(this.props.storeId, {
          checkoutStatus: CheckoutStatus.ShippingMethodAdded
        });
      } catch (error) {
        console.log("Error updating checkout status");
      }
    }
  };

  clearFocus = () => {
    // This hacky thing comes courtesy of safari,
    // which is bad at firing field blur events on fields in destroyed dom trees.
    const elem = document.createElement("input");
    document.body.appendChild(elem);
    elem.focus();
    elem.blur();
    elem.remove();
  };

  handleSaveBilling = async (billing: CheckoutBillingDataState) => {
    const { cartListings } = this.props;
    this.props.dispatch(updateBilling(billing));
    try {
      await updateStatus(this.props.storeId, {
        checkoutStatus: CheckoutStatus.BillingInformationAdded
      });
    } catch (error) {
      console.log("Error updating checkout status");
    }
    TrackCheckoutAddPayment(cartListings);
    this.clearFocus();
  };

  handlePlaceOrder = async () => {
    this.setState((prevState) => ({ ...prevState, orderPending: true }));
    const response = await this.props.dispatch(requestPlaceOrder());
    if (response.isSuccess) {
      this.props.dispatch(push(this.props.orderCompletePath));
      try {
        await updateStatus(this.props.storeId, {
          checkoutStatus: CheckoutStatus.Complete
        });

        //@ts-ignore
          fbq('track', 'Purchase', {
          content_name: 'Store #' + this.props.storeId,
          content_category: 'Listings',
          content_ids: [this.props.cartListings.map(cartListing => cartListing.listingId)],
          content_type: 'product',
          value: this.props.cartSum,
          currency: 'USD'
        });
      } catch (error) {
        console.log("Error updating checkout status");
      }
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          orderPending: false,
          editingPayForm: true,
          hasToken: false,
          nuveiRequestId: uuid.v4()
        }),
        () => {
          this.fetchPaymentUrl();
        }
      );
    }
  };

  async componentDidMount() {
    const { readyForEstimate, readyForDiscountEstimate } = this.props;
    try {
      await updateStatus(this.props.storeId, {
        checkoutStatus: CheckoutStatus.CheckoutScreen
      });
    } catch (error) {
      console.log("Error updating checkout status");
    }
    this.tryUpdateShipping();
    this.markNextUnenteredForEdit();
    if (readyForEstimate) {
      this.tryUpdateEstimates();
    }
    if (readyForDiscountEstimate) {
      this.tryUpdateDiscountEstimate();
    }
    const [privacyUrl, termsUrl] = await Promise.all([
      ExternalUrls.LuLaRoePrivacyPolicyUrl(),
      ExternalUrls.LuLaRoeTermsUrl()
    ]);
    this.setState(
      (prevState) => ({
        ...prevState,
        llrPrivacyUrl: privacyUrl,
        llrTermsUrl: termsUrl,
        nuveiRequestId: uuid.v4()
      }),
      () => {
        this.fetchPaymentUrl();
      }
    );
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.props.dispatch(clearBillingState());
  }

  componentDidUpdate(prevProps: Props) {
    const { readyForEstimate, readyForDiscountEstimate } = this.props;
    this.tryUpdateShipping(prevProps);
    if (readyForEstimate) {
      this.tryUpdateEstimates(prevProps);
    }
    if (readyForDiscountEstimate) {
      this.tryUpdateDiscountEstimate(prevProps);
    }
  }

  timeoutPromise = (timeout: number) =>
    new Promise((resolve) => setTimeout(resolve, timeout));

  tryUpdateShipping = async (prevProps?: Props) => {
    const { cartListings, cartCount, customer } = this.props;
    const { cartListings: prevListings = null, customer: prevCustomer = null } =
      prevProps || {};

    if (cartCount === 0) {
      return;
    }

    if (!this.state.updatingShipping) {
      let changed = false;
      if (cartListings.length > 0 && cartListings !== prevListings) {
        changed = true;
      }

      const newPromo = customer.data && customer.data.promoCode;
      const oldPromo =
        prevCustomer && prevCustomer.data && prevCustomer.data.promoCode;
      if (newPromo !== oldPromo) {
        changed = true;
        // A promo code either changed or was entered for the first time.
      }
      if (changed) {
        if (!(await this.validatePromoCode())) {
          return;
        }
        this.setState((prevState) => ({
          ...prevState,
          updatingShipping: true
        }));
        await this.props.dispatch(requestShippingAmount());
        if (this.unmounting) return;
        this.setState((prevState) => ({
          ...prevState,
          updatingShipping: false
        }));
      }
    }
  };

  tryUpdateDiscountEstimate = async (prevProps?: Props) => {
    const { cartListings, customer, cartCount } = this.props;
    const { cartListings: prevListings = null, customer: prevCustomer = null } =
      prevProps || {};

    const hasItems = cartCount > 0;

    if (!hasItems || !customer.data) {
      return;
    }

    if (!this.state.updatingDiscountEstimate) {
      let changed = false;
      if (cartListings !== prevListings) changed = true;
      else if (!prevCustomer || !prevCustomer.data) {
        changed = true;
      } else if (customer.data.email !== prevCustomer.data.email) {
        changed = true;
      }
      const newPromo = customer.data && customer.data.promoCode;
      const oldPromo =
        prevCustomer && prevCustomer.data && prevCustomer.data.promoCode;
      if (newPromo !== oldPromo) {
        changed = true;
        // A promo code either changed or was entered for the first time.
      }

      if (changed) {
        if (!(await this.validatePromoCode())) {
          return;
        }
        this.setState((prevState) => ({
          ...prevState,
          updatingDiscountEstimate: true,
          discountEstimateRetries: 0
        }));

        await this.props.dispatch(requestDiscountEstimate());

        this.setState((prevState) => ({
          ...prevState,
          updatingDiscountEstimate: false,
          estimateRetries: 0
        }));
      }
    }
  };

  tryUpdateEstimates = async (prevProps?: Props) => {
    const { cartListings, delivery, billing, customer, cartCount } = this.props;
    const {
      cartListings: prevListings = null,
      delivery: prevDelivery = null,
      billing: prevBilling = null,
      customer: prevCustomer = null
    } = prevProps || {};

    const hasItems = cartCount > 0;

    if (!hasItems || !delivery.data || !billing.data || !customer.data) {
      return;
    }

    if (!this.state.updatingEstimate) {
      let changed = false;
      if (cartListings !== prevListings) changed = true;
      else if (!prevDelivery || delivery.data !== prevDelivery.data)
        changed = true;
      else if (!prevBilling || billing.data !== prevBilling.data)
        changed = true;
      else if (!prevCustomer || !prevCustomer.data) {
        changed = true;
      } else if (customer.data.email !== prevCustomer.data.email) {
        changed = true;
      } else if (customer.data.promoCode !== prevCustomer.data.promoCode) {
        changed = true;
      }

      if (changed) {
        if (!(await this.validatePromoCode())) {
          return;
        }
        this.setState((prevState) => ({
          ...prevState,
          updatingEstimate: true,
          estimateRetries: 0
        }));
        let retryCount = 0;
        while (
          retryCount < 20 &&
          !(await this.props.dispatch(requestOrderEstimate()))
        ) {
          retryCount++;
          if (this.unmounting) return;
          this.setState({ estimateRetries: retryCount });
          await this.timeoutPromise(5000);
        }
        if (this.unmounting) return;
        this.setState((prevState) => ({
          ...prevState,
          updatingEstimate: false,
          estimateRetries: 0
        }));
      }
    }
  };

  openShippingPolicy = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalContents: this.props.shippingPolicy,
      showModal: true
    }));
  };

  openReturnsPolicy = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalContents: this.props.returnsPolicy,
      showModal: true
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalContents: undefined,
      showModal: false
    }));
  };

  customerInfoSection = () => {
    const {
      customer,
      customerEmail,
      customerFirstName,
      customerLastName
    } = this.props;
    const { orderPending } = this.state;

    return (
      <>
        <p>
          <span className={Styles.column_number}>1 </span> Customer Information
        </p>
        {customer.editing ? (
          <>
            <CustomerForm
              existing={customer.data}
              submitAction={this.handleSaveCustomer}
              customerEmail={customerEmail}
              customerFirstName={customerFirstName}
              customerLastName={customerLastName}
              showPromo={true}
              failureErrors={customer.errors || {}}
            />
            <div className={Styles.policyText}>
              By clicking 'Next', you confirm that you have read and understood
              this Retailer's{" "}
              <span
                className={Styles.policyLink}
                onClick={this.openShippingPolicy}
              >
                Shipping Policy
              </span>{" "}
              and{" "}
              <span
                className={Styles.policyLink}
                onClick={this.openReturnsPolicy}
              >
                Returns Policy
              </span>
              , you acknowledge the retailer may contact you directly, and
              accept our{" "}
              <a
                href={this.state.llrTermsUrl}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href={this.state.llrPrivacyUrl}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </a>
              .
            </div>
          </>
        ) : (
          customer.data && (
            <CustomerFormSummary
              customerInfo={customer.data}
              canEdit={!orderPending}
              error={
                this.props.customer.failed
                  ? "There is a problem with your customer information"
                  : undefined
              }
              handleEdit={() => this.props.dispatch(markCustomerEditing())}
            />
          )
        )}
      </>
    );
  };

  deliveryInfoSection = () => {
    const {
      delivery,
      customer,
      estimatedShippingAmount,
      pickupDetails
    } = this.props;
    const { orderPending } = this.state;

    return (
      <>
        <p>
          <span className={Styles.column_number}>2 </span> Shipping Method
        </p>
        {delivery.editing ? (
          <DeliveryForm
            existing={delivery.data}
            customer={customer.data}
            estimatedShipping={estimatedShippingAmount}
            pickupDetails={pickupDetails}
            submitAction={this.handleSaveDelivery}
            failureErrors={delivery.errors || {}}
          />
        ) : (
          delivery.data && (
            <DeliveryFormSummary
              deliveryInfo={delivery.data}
              handleEdit={() => this.props.dispatch(markDeliveryEditing())}
              pickupDetails={pickupDetails}
              error={
                this.props.delivery.failed
                  ? "There is a problem with your delivery information"
                  : undefined
              }
              canEdit={!orderPending}
            />
          )
        )}
      </>
    );
  };

  fetchPaymentUrl = async () => {
    const { hasToken, nuveiRequestId } = this.state;
    const url = await ExternalUrls.TokenPayFormPath(
      this.props.storeId,
      hasToken,
      nuveiRequestId
    );
    this.setState((prevState) => ({
      ...prevState,
      payFormUrl: url
    }));
  };

  editBillingAddress = () => {
    this.props.dispatch(
      markBillingEditing(true, this.props.billing.editingPayment)
    );
  };
  editPayment = () => {
    this.props.dispatch(
      markBillingEditing(this.props.billing.editingAddress, true)
    );
  };

  billingInfoSection = () => {
    const { billing, delivery, storeId } = this.props;
    const { orderPending, hasToken, nuveiRequestId, payFormUrl } = this.state;

    return (
      <>
        <p>
          <span className={Styles.column_number}>3 </span> Billing Information
        </p>
        {billing.data && !billing.editingAddress && (
          <div>
            <BillingFormSummary
              billingInfo={billing.data}
              canEdit={!orderPending}
              handleEdit={this.editBillingAddress}
            />
          </div>
        )}
        {billing.editing && (
          <BillingForm
            storeId={storeId}
            hasToken={hasToken}
            payFormUrl={payFormUrl}
            nuveiRequestId={nuveiRequestId}
            editingPayForm={billing.editingPayment}
            editingAddress={billing.editingAddress}
            delivery={delivery.data}
            existing={billing.data}
            paymentInProcess={orderPending}
            submitAction={this.handleSaveBilling}
            failureErrors={billing.errors || {}}
          />
        )}
        {billing.data && !billing.editingPayment && (
          <PaymentFormSummary
            canEdit={!orderPending}
            handleEdit={this.editPayment}
          />
        )}
      </>
    );
  };

  cartListingDisplay = (listing: CartListingType) => {
    const primaryImage =
      listing.images ? listing.images.find((image) => image.isPrimaryImage) || listing.images[0] : undefined;
    const listingImageUrl = primaryImage
      ? primaryImage.thumbnailImageUrl || primaryImage.standardImageUrl
      : "";

    const cartDuplicateItems = [];
    const sizeName = listing.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name;
    const quantityClaimed = listing.claimBySessions
      .find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou)?.quantityClaimed || 0;

    for (let i = 0; i < quantityClaimed; i++) {
      cartDuplicateItems.push(
        <div className={`${Styles.checkout_item} clearfix`} key={`${listing.listingId}-${i}`}>
          <div className={Styles.checkout_item_image_area}>
            <img
              src={listingImageUrl}
              className={Styles.checkout_item_image}
              alt={listing.styleDisplayName}
            />
          </div>
          <div className={Styles.checkoutItemDetails}>
            <span>{listing.styleDisplayName}</span>
            <span style={{ float: "right" }}>
              {listing.salePrice === undefined
                ? null
                : Formatter.format(listing.salePrice)}
            </span>
            {sizeName ? (
              <div className={Styles.shopping_cart_size}>
                <span>Size: {sizeName}</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <>
        {cartDuplicateItems}
      </>
    );
  };

  completeOrderSection = () => {
    const {
      estimate,
      readyToPay,
      orderFailed,
      avsFailed,
      showOhSnap,
      cartListings,
      readyForEstimate,
      shippingAmount,
      cartSum,
      customer,
      freeOrder,
      discountEstimate
    } = this.props;
    const { updatingEstimate, estimateRetries, offer } = this.state;

    return (
      <>
        <p>
          <span className={Styles.column_number}>4 </span> Review and Checkout
        </p>
        {freeOrder && (
          <OhSnap
            errorText={
              "Free orders cannot be completed online. Please contact the retailer for assistance."
            }
          />
        )}
        {orderFailed && showOhSnap && (
          <OhSnap
            errorText={
              avsFailed
                ? "The entered billing address details did not match the address on file for your card. Please correct the highlighted fields and double check your payment details before trying again."
                : "There are some errors in your information. Please correct the highlighted fields and try again."
            }
          />
        )}
        <p className={Styles.input_header}>Review your Order</p>
        <div className="col-12">
          {cartListings.map(this.cartListingDisplay)}
        </div>
        <div className={Styles.summary}>
          {readyForEstimate ? (
            <>
              {updatingEstimate ? (
                <>
                  {estimateRetries > 0 && estimateRetries <= 3 && (
                    <div className="mb-2">
                      We're figuring out the tax for your order. This shouldn't
                      take long!
                    </div>
                  )}
                  {estimateRetries > 3 && (
                    <div className="mb-2">
                      The tax calculation is taking a little longer than usual.
                      Do not refresh this page—we'll give you an update here
                      once it's done!
                    </div>
                  )}
                  <div className="mb-2">
                    <LoadingSpinner />
                  </div>
                </>
              ) : (
                <>
                  {(estimate.response && estimate.response.isSuccess && (
                    <EstimatedOrderSummary
                      estimatedSubtotal={estimate.response.subtotal}
                      promoCode={customer.data && customer.data.promoCode}
                      dollarDiscountAmount={
                        estimate.response.dollarDiscountAmount
                      }
                      showPromo={true}
                      estimatedItemTax={estimate.response.itemTax}
                      estimatedShippingAmount={estimate.response.shippingAmount}
                      estimatedShippingTax={estimate.response.shippingTax}
                      estimatedGrandTotal={estimate.response.grandTotal}
                    />
                  )) || (
                    <div>
                      Looks like we're having trouble calculating tax for your
                      order. If you need assistance, please contact your
                      retailer using the information listed at the bottom of
                      this page.
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <EstimatedOrderSummary
                estimatedSubtotal={cartSum}
                showPromo={true}
                promoCode={customer.data && customer.data.promoCode}
                dollarDiscountAmount={discountEstimate}
                estimatedShippingAmount={shippingAmount}
              />
            </>
          )}

          {/* Only allow checkout button if we've got a full suite of data with no failures. */}
          <LoadingButton
            inProgress={this.state.orderPending}
            disabled={!readyToPay}
            block={true}
            onClick={this.handlePlaceOrder}
          >
            Check Out
          </LoadingButton>
        </div>
        <div className={Styles.policyText}>
          By clicking the Checkout button, you confirm that you have read and
          understood this Retailer's{" "}
          <span className={Styles.policyLink} onClick={this.openShippingPolicy}>
            Shipping Policy
          </span>{" "}
          and{" "}
          <span className={Styles.policyLink} onClick={this.openReturnsPolicy}>
            Returns Policy
          </span>
          , and accept our{" "}
          <a
            href={this.state.llrTermsUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href={this.state.llrPrivacyUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </a>
          .
        </div>
      </>
    );
  };

  render() {
    const {
      readyToBeginCheckout,
      homepagePath,
      customer,
      delivery
    } = this.props;
    const { showModal, modalContents } = this.state;

    if (!readyToBeginCheckout) {
      return <Redirect to={homepagePath} />;
    }

    return (
      <>
        {/* Terms and conditions modal */}
        <div
          id="myModal"
          className={classnames({
            [Styles.modal]: true,
            [Styles.hidden]: !showModal
          })}
        >
          <div className={Styles.modal_content}>
            <span className={Styles.close} onClick={this.closeModal}>
              &times;
            </span>
            <p>{modalContents}</p>
          </div>
        </div>
        <div>
          <h2 className={Styles.manage_header}>Secure Checkout</h2>
        </div>
        <div className={Styles.checkoutContainer}>
          <div className={Styles.checkoutSection}>
            {this.customerInfoSection()}
          </div>
          {/* Only show delivery section if customer is saved. */}
          {customer.data && (
            <div className={Styles.checkoutSection}>
              {this.deliveryInfoSection()}
            </div>
          )}
          {/* Only show billing section and order summary if customer and delivery are saved. */}
          {customer.data && delivery.data && (
            <>
              <div className={Styles.checkoutSection}>
                {this.billingInfoSection()}
              </div>
              <div className={Styles.checkoutSection}>
                {this.completeOrderSection()}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, void, State> = (state) => {
  const {
    alias,
    shippingPolicy,
    returnsPolicy,
    pickupDetails,
    id
  } = state.store;

  const {
    customerInfo,
    deliveryInfo,
    billingInfo,
    shipping,
    estimate,
    order,
    customerEmail,
    customerFirstName,
    customerLastName,
    facebookId,
    discountEstimate
  } = state.checkout;

  const customerGood =
    !!customerInfo.data && !customerInfo.editing && !customerInfo.failed;
  const deliveryGood =
    !!deliveryInfo.data && !deliveryInfo.editing && !deliveryInfo.failed;
  const billingGood =
    !!billingInfo.data && !billingInfo.editing && !billingInfo.failed;
  const estimateGood =
    !estimate.inProcess && !!estimate.response && estimate.response.isSuccess;
  const freeOrder =
    estimateGood &&
    !!estimate.response &&
    estimate.response.isSuccess &&
    estimate.response.grandTotal <= 0;
  const orderFailed =
    !order.inProcess && !!order.response && !order.response.isSuccess;
  const avsFailed =
    !order.inProcess &&
    !!order.response &&
    !order.response.isSuccess &&
    (!!order.response.failures.addressError ||
      !!order.response.failures.zipCodeError);
  const showOhSnap =
    orderFailed &&
    (!customerGood || !deliveryGood || !billingGood || !estimateGood);
  const hasPromo = !!customerInfo.data?.promoCode;

  const currentCartCount = cartCount(state);

  return {
    customer: customerInfo,
    delivery: deliveryInfo,
    billing: billingInfo,
    shippingAmount:
      deliveryInfo.data && deliveryInfo.data.method === DeliveryMethod.Pickup
        ? 0
        : shipping.response && shipping.response.isSuccess
        ? shipping.response.shippingAmount
        : undefined,
    estimatedShippingAmount:
      shipping.response && shipping.response.isSuccess
        ? shipping.response.shippingAmount
        : undefined,
    estimate,
    readyToBeginCheckout: currentCartCount > 0,
    cartListings: getClaimedListings(state),
    shippingPolicy: shippingPolicy || "",
    returnsPolicy: returnsPolicy || "",
    homepagePath: PageUrls.StoreHomepage(alias),
    orderCompletePath: PageUrls.OrderCompletePage(alias),
    readyForEstimate: customerGood && deliveryGood && billingGood,
    readyForDiscountEstimate: customerGood && hasPromo,
    // Only ready if we have good data for all forms and nothing is being edited.
    readyToPay:
      customerGood && deliveryGood && billingGood && estimateGood && !freeOrder,
    pickupDetails: pickupDetails || { enabled: false },
    orderFailed,
    avsFailed,
    showOhSnap,
    storeId: id,
    cartSum: cartSubtotal(state),
    cartCount: currentCartCount,
    customerEmail,
    customerFirstName,
    customerLastName,
    facebookId,
    freeOrder,
    discountEstimate,
  };
};

export default withRouter(connect(mapStateToProps)(CheckoutPage));
